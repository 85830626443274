import { connect } from 'react-redux';
import ReduxState from '../../Redux/Types/ReduxState';
import VideoListener from '../Components/VideoListener';
import {
  getIsReadyFromState,
  getSocketFromState,
  getUsersFromState,
} from '../../Server/Selectors/ServerSelectors';
import { getGoogleProfileFromState } from '../../GoogleProfile/Selectors/GoogleProfileSelectors';
import { getMovieNightRoomFromState } from '../../MainMenu/Selectors/MainMenuSelectors';

const mapStateToProps = (state: ReduxState) => {
  return {
    socket: getSocketFromState(state),
    myProfile: getGoogleProfileFromState(state),
    isReady: getIsReadyFromState(state),
    users: getUsersFromState(state),
    room: getMovieNightRoomFromState(state),
  };
};

const mapDispatchToProps = (_dispatch: any) => {
  return {};
};

const VideoListenerContainer = connect(mapStateToProps, mapDispatchToProps)(VideoListener);
export default VideoListenerContainer;
