import { GoogleProfile } from '../../../Types/GoogleProfile';

export const GOOGLE_LOG_IN_SUCCESS = 'GOOGLE_LOG_IN_SUCCESS';
export interface GoogleLogInSuccessAction {
  type: typeof GOOGLE_LOG_IN_SUCCESS;
  payload: GoogleProfile;
}

export const googleLogInSuccessActionActionCreator = (
  payload: GoogleProfile,
): GoogleLogInSuccessAction => {
  return { type: GOOGLE_LOG_IN_SUCCESS, payload: payload };
};

export const GOOGLE_LOG_IN_FAILURE = 'GOOGLE_LOG_IN_FAILURE';
export interface GoogleLogInFailureAction {
  type: typeof GOOGLE_LOG_IN_FAILURE;
  payload: any;
}

export const googleLogInFailureActionActionCreator = (payload: any): GoogleLogInFailureAction => {
  return { type: GOOGLE_LOG_IN_FAILURE, payload: payload };
};

export const CHANGE_SHOULD_INITIATE_AUTO_LOGIN = 'CHANGE_SHOULD_INITIATE_AUTO_LOGIN';
export interface ChangeShouldInitiateAutoLoginAction {
  type: typeof CHANGE_SHOULD_INITIATE_AUTO_LOGIN;
  payload: boolean;
}

export const changeShouldInitiateAutoLoginActionCreator = (
  shouldInitiateAutoLogin: boolean,
): ChangeShouldInitiateAutoLoginAction => {
  return { type: CHANGE_SHOULD_INITIATE_AUTO_LOGIN, payload: shouldInitiateAutoLogin };
};

export type GoogleLogInActions =
  | GoogleLogInSuccessAction
  | GoogleLogInFailureAction
  | ChangeShouldInitiateAutoLoginAction;
