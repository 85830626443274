import { connect } from 'react-redux';
import GoogleLogIn from '../Components/GoogleLogIn';
import ReduxState from '../../../../Redux/Types/ReduxState';
import { GoogleProfile } from '../../../Types/GoogleProfile';
import {
  googleLogInFailureActionActionCreator,
  googleLogInSuccessActionActionCreator,
} from '../Actions/GoogleLogInActions';
import { getShouldInitiateAutoLoginFromState } from '../../../Selectors/GoogleProfileSelectors';
import {
  updateAllMessagesActionCreator,
  updateAllUsersActionCreator,
} from '../../../../Server/Actions/ServerListenerActions';
import { buildMessageDemoMode, MovieNightUser } from '../../../../Server/Types/ServerTypes';

const changeIsTyping = (users: { [googleID: string]: MovieNightUser }, isTyping: boolean) => {
  return {
    ...users,
    DemoServerID: {
      ...users.DemoServerID,
      isTyping: isTyping,
    },
  };
};

const addServerMessage = (dispatch: any, message: string, systemMessage: boolean = false) => {
  dispatch(
    updateAllMessagesActionCreator(
      [buildMessageDemoMode('DemoServerID', message, systemMessage)],
      true,
    ),
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {
    shouldInitiateAutoLogin: getShouldInitiateAutoLoginFromState(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSuccess: (googleProfile: GoogleProfile) =>
      dispatch(googleLogInSuccessActionActionCreator(googleProfile)),
    onFailure: (error: any) => dispatch(googleLogInFailureActionActionCreator(error)),
    onDemoMode: (users: { [googleID: string]: MovieNightUser }) => {
      dispatch(updateAllUsersActionCreator(users));
      addServerMessage(dispatch, 'Welcome to YouTube Night!');

      setTimeout(() => {
        users = changeIsTyping(users, true);
        dispatch(updateAllUsersActionCreator(users));

        setTimeout(() => {
          addServerMessage(dispatch, 'This is a demo of our chat :D');
          users = changeIsTyping(users, false);
          dispatch(updateAllUsersActionCreator(users));

          setTimeout(() => {
            users = changeIsTyping(users, true);
            dispatch(updateAllUsersActionCreator(users));

            setTimeout(() => {
              addServerMessage(
                dispatch,
                'You can activate dark mode, custom chat colors, and more in settings',
              );
              users = changeIsTyping(users, false);
              dispatch(updateAllUsersActionCreator(users));

              setTimeout(() => {
                users = changeIsTyping(users, true);
                dispatch(updateAllUsersActionCreator(users));

                setTimeout(() => {
                  addServerMessage(dispatch, 'Now you try sending a message! :)');
                  users = changeIsTyping(users, false);
                  dispatch(updateAllUsersActionCreator(users));

                  setTimeout(() => {
                    users = changeIsTyping(users, true);
                    dispatch(updateAllUsersActionCreator(users));

                    setTimeout(() => {
                      addServerMessage(
                        dispatch,
                        'Lastly, if you enjoy using YouTube Night, please consider supporting us on Patreon.' +
                          ' All profits go to Feeding America! <3',
                        true,
                      );
                      users = changeIsTyping(users, false);
                      dispatch(updateAllUsersActionCreator(users));
                    }, 3000);
                  }, 1000);
                }, 1500);
              }, 1000);
            }, 2500);
          }, 1000);
        }, 2000);
      }, 500);
    },
  };
};

const GoogleLogInContainer = connect(mapStateToProps, mapDispatchToProps)(GoogleLogIn);
export default GoogleLogInContainer;
