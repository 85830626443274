import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Slide } from '@material-ui/core';

export const createSnackbar = (
  tooltip: string,
  severity: 'success' | 'info' | 'warning' | 'error',
  autoHideDuration: number | undefined,
  isOpen: boolean,
  onClose: () => void,
  shouldAnimate: boolean,
  key: number,
  className?: string,
): JSX.Element => {
  return (
    <Snackbar
      key={key}
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      TransitionComponent={shouldAnimate ? transitionDown : undefined}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      className={className + ' SnackbarGeneral'}
      transitionDuration={shouldAnimate ? undefined : 0}
    >
      <Alert severity={severity} onClose={onClose} variant={'filled'}>
        {tooltip}
      </Alert>
    </Snackbar>
  );
};

export const transitionDown = (props: any) => {
  return <Slide {...props} direction="down" />;
};
