import ReduxState from '../../Redux/Types/ReduxState';
import { connect } from 'react-redux';
import MainMenu from '../Components/MainMenu';
import {
  changeAutoJoinActionCreator,
  createMovieNightRoomActionCreator,
  joinMovieNightRoomActionCreator,
  makeJoinRoomPayload,
} from '../Actions/MainMenuActions';
import {
  getGoogleProfileFromState,
  getLoggedInStatusFromState,
} from '../../GoogleProfile/Selectors/GoogleProfileSelectors';
import { buildMovieNightRoom, YoutubeInfo } from '../../Shared/Types/Room';
import {
  getAlreadyAutoJoinedFromState,
  getPublicRoomsFromState,
} from '../Selectors/MainMenuSelectors';
import { getSocketFromState } from '../../Server/Selectors/ServerSelectors';
import { changeCurrentURLAction } from '../../MovieNight/Actions/MovieNightActions';
import { getYouTubeInfo } from '../../Shared/Utils/WebsiteURLUtils';

const mapStateToProps = (state: ReduxState) => {
  return {
    loggedIn: getLoggedInStatusFromState(state),
    googleID: getGoogleProfileFromState(state)?.googleID,
    alreadyAutoJoined: getAlreadyAutoJoinedFromState(state),
    publicRooms: getPublicRoomsFromState(state),
    socket: getSocketFromState(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onCreateRoom: (
      googleID: string | undefined,
      roomName: string,
      isPrivateRoom: boolean,
      keepControl: boolean,
      url: string,
      youtubeInfo: YoutubeInfo,
    ) => {
      dispatch(
        createMovieNightRoomActionCreator(
          buildMovieNightRoom(googleID, roomName, isPrivateRoom, keepControl, url, youtubeInfo),
        ),
      );

      dispatch(changeCurrentURLAction(url, 'Ignore', youtubeInfo.title, youtubeInfo.channel));
    },

    onJoinRoom: (roomName: string, url: string) => {
      dispatch(
        joinMovieNightRoomActionCreator(makeJoinRoomPayload(roomName, window.location.href)),
      );

      let youtubeInfo = getYouTubeInfo();
      dispatch(changeCurrentURLAction(url, 'Ignore', youtubeInfo.title, youtubeInfo.channel));
    },

    onAutoJoinChange: (autoJoined: boolean) => {
      dispatch(changeAutoJoinActionCreator(autoJoined));
    },
  };
};

const MainMenuContainer = connect(mapStateToProps, mapDispatchToProps)(MainMenu);
export default MainMenuContainer;
