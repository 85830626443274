export interface MessageInputState {
  isTyping: boolean;
  isSending: boolean;
  messagesSending: string[];
}

export const initialMessageInputState: MessageInputState = {
  isTyping: false,
  isSending: false,
  messagesSending: [],
};
