import React from 'react';
import { GoogleLogout } from 'react-google-login';
import { googleClientID, logoutText } from '../../../Constants/GoogleConstants';
import { isDevEnv } from '../../../../index';
import GoogleButton from 'react-google-button';
import '../../../Styles/GoogleProfile.css';

interface GoogleLogOutProps {
  onLogoutSuccess: () => void;
  onLogoutFailure: () => void;
}

class GoogleLogOut extends React.PureComponent<GoogleLogOutProps> {
  onLogoutProduction = () => {
    chrome.runtime.sendMessage({ type: 'Logout' }, (response: string) => {
      if (response === 'Success') {
        this.props.onLogoutSuccess();
      } else {
        this.props.onLogoutFailure();
      }
    });
  };

  render = () => {
    if (isDevEnv) {
      return (
        <GoogleLogout
          clientId={googleClientID}
          buttonText={logoutText}
          onLogoutSuccess={this.props.onLogoutSuccess}
          onFailure={this.props.onLogoutFailure}
        />
      );
    } else {
      return (
        <div className={'ProductionGoogleButton'}>
          <GoogleButton label={'Sign Out'} type={'dark'} onClick={this.onLogoutProduction} />
        </div>
      );
    }
  };
}

export default GoogleLogOut;
