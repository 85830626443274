import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './Redux/Store/Store';
import MovieNightContainer from './MovieNight/Containers/MovieNightContainer';
import './Shared/Styles/SharedStyles.css';
import {
  addGTag,
  addLinkTagToDocument,
  addScriptToDocument,
  ProductionEnvironment,
  unmountApp,
} from './Shared/Utils/GeneralUtils';
import { changeCurrentURLAction } from './MovieNight/Actions/MovieNightActions';
import ReduxState from './Redux/Types/ReduxState';
import {
  getYouTubeInfo,
  isMyURLSameAsBaseURL,
  isNewURLValidURL,
} from './Shared/Utils/WebsiteURLUtils';
import { MovieNightRoom, YoutubeInfo } from './Shared/Types/Room';
import MovieNightWebsite from './MovieNightWebsite/Components/MovieNightWebsite';

export const isDevEnv = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
export const movieNightRef: React.RefObject<HTMLDivElement> = React.createRef();
export let productionEnvironment = ProductionEnvironment.None;

console.log = function no_console() {};
const isWebsite = true;

if (isDevEnv) {
  if (isWebsite) {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <MovieNightWebsite />
        </Provider>
      </React.StrictMode>,
      document.getElementById('MovieNightRoot'),
    );
  } else {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <MovieNightContainer offsetAppFromTop={'0'} appRef={movieNightRef} />
        </Provider>
      </React.StrictMode>,
      document.getElementById('MovieNightRoot'),
    );
  }
} else {
  if (!document.location.href.includes('youtube.com')) {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <MovieNightWebsite />
        </Provider>
      </React.StrictMode>,
      document.getElementById('MovieNightRoot'),
    );
  } else {
    let appStarted = false;

    // Add CSS & Fonts Early
    addLinkTagToDocument(
      'https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap',
    );
    addLinkTagToDocument('https://fonts.googleapis.com/css2?family=Inria+Sans&display=swap');
    addLinkTagToDocument(
      'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.6/css/bootstrap.min.css',
    );
    addLinkTagToDocument('https://fonts.googleapis.com/icon?family=Material+Icons');

    chrome.runtime.onMessage.addListener(function (request, sender, _sendResponse) {
      switch (request.type) {
        case 'Start':
          addScriptToDocument('https://www.googletagmanager.com/gtag/js?id=UA-165372924-1');
          addGTag();

          if (appStarted && request.source === 'Clicked') {
            console.log('Clicked | Stopping App');
            appStarted = false;

            let state = store.getState();
            if (state.serverState.socket) {
              state.serverState.socket.disconnect();
            }

            unmountApp();
          } else if (!appStarted) {
            console.log('Clicked | Starting App');

            appStarted = true;
            console.log('Welcome to YouTube Night!');

            // Render App
            const app = document.createElement('div');
            app.id = 'MovieNightRoot';
            document.body.appendChild(app);

            // YouTube
            if (document.location.href.includes('youtube.com/watch')) {
              productionEnvironment = ProductionEnvironment.Youtube;

              document.getElementsByTagName('html')[0].style.fontSize = '16px';

              const checkForYouTubePlayer = setInterval(() => {
                console.log('Searching');
                if (document.getElementById('content') != null) {
                  ReactDOM.render(
                    <React.StrictMode>
                      <Provider store={store}>
                        <MovieNightContainer offsetAppFromTop={'56px'} appRef={movieNightRef} />
                      </Provider>
                    </React.StrictMode>,
                    app,
                  );

                  const youTubeDiv: HTMLDivElement = document.getElementById(
                    'content',
                  ) as HTMLDivElement;
                  youTubeDiv.style.width = 'calc(100% - 300px)';
                  clearInterval(checkForYouTubePlayer);
                }
              }, 100);

              if (
                !(document.getElementById(
                  'player-theater-container',
                ) as HTMLElement).hasChildNodes()
              ) {
                if (document.getElementsByTagName('video')[0]) {
                  document.getElementsByTagName('video')[0].click();
                  const theaterMode: HTMLElement | null = document.querySelector(
                    '[title="Theater mode (t)"]',
                  );
                  if (theaterMode) {
                    theaterMode.click();
                  }
                  document.getElementsByTagName('video')[0].click();
                }
              }
            }
          }
          break;

        case 'Stop':
          console.log('URL Changed | Stopping App');
          appStarted = false;

          let state: ReduxState = store.getState();
          if (state.serverState.socket) {
            state.serverState.socket.disconnect();
          }

          unmountApp();
          break;

        case 'URL Change':
          let reduxState: ReduxState = store.getState();
          let previousURL: string = reduxState.movieNight.currentURL;
          let room: MovieNightRoom | undefined = reduxState.mainMenu.room;
          let socket: SocketIOClient.Socket | undefined = reduxState.serverState.socket;
          if (room && socket) {
            // New Valid URL and not previously at that URL
            if (
              isNewURLValidURL(window.location.href) &&
              !isMyURLSameAsBaseURL(previousURL, window.location.href, room.roomName)
            ) {
              console.log('New valid URL');
              let youtubeInfo: YoutubeInfo = getYouTubeInfo();
              store.dispatch(
                changeCurrentURLAction(
                  window.location.href,
                  'Valid',
                  youtubeInfo.title,
                  youtubeInfo.channel,
                ),
              );
            }

            // New invalid URL and not previously at an invalid URL
            else if (
              isMyURLSameAsBaseURL(room.url, previousURL, room.roomName) &&
              !isMyURLSameAsBaseURL(room.url, window.location.href, room.roomName)
            ) {
              console.log('New invalid URL');
              store.dispatch(changeCurrentURLAction(window.location.href, 'Invalid', '', ''));
            }
          }
          break;

        default:
          break;
      }
    });
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
