import { EventListener, Options as LottieOptions } from 'react-lottie';

export const buildLottieOptions = (
  animationData: any,
  loop: boolean = true,
  autoPlay: boolean = true,
): LottieOptions => {
  return {
    loop: loop,
    autoplay: autoPlay,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
};

export const buildLottieEventListener = (
  eventName:
    | 'complete'
    | 'loopComplete'
    | 'enterFrame'
    | 'segmentStart'
    | 'config_ready'
    | 'data_ready'
    | 'loaded_images'
    | 'DOMLoaded'
    | 'destroy',
  callback: () => void,
): EventListener => {
  return {
    eventName,
    callback,
  };
};
