import { Message, MovieNightUser } from '../Types/ServerTypes';

export const UPDATE_ALL_USERS = 'UPDATE_ALL_USERS';
export interface UpdateAllUsersAction {
  type: typeof UPDATE_ALL_USERS;
  payload: { [googleID: string]: MovieNightUser };
}

export const updateAllUsersActionCreator = (users: {
  [googleID: string]: MovieNightUser;
}): UpdateAllUsersAction => {
  return {
    type: UPDATE_ALL_USERS,
    payload: users,
  };
};

interface UpdateAllMessagesPayload {
  messages: Message[];
  demoMode: boolean;
}

export const UPDATE_ALL_MESSAGES = 'UPDATE_ALL_MESSAGES';
export interface UpdateAllMessagesAction {
  type: typeof UPDATE_ALL_MESSAGES;
  payload: UpdateAllMessagesPayload;
}

export const updateAllMessagesActionCreator = (
  messages: Message[],
  demoMode: boolean,
): UpdateAllMessagesAction => {
  return {
    type: UPDATE_ALL_MESSAGES,
    payload: { messages, demoMode },
  };
};

export const UPDATE_IS_READY = 'UPDATE_IS_READY';
export interface UpdateIsReadyAction {
  type: typeof UPDATE_IS_READY;
  payload: { [googleID: string]: boolean };
}

export const updateIsReadyActionCreator = (isReady: {
  [googleID: string]: boolean;
}): UpdateIsReadyAction => {
  return {
    type: UPDATE_IS_READY,
    payload: isReady,
  };
};

export type ServerListenerActions =
  | UpdateAllUsersAction
  | UpdateAllMessagesAction
  | UpdateIsReadyAction;
