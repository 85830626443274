import { store } from '../../Redux/Store/Store';
import ReactDOM from 'react-dom';
import { productionEnvironment } from '../../index';
import { RESET_STORE } from '../../Redux/Reducers/rootReducer';

export enum ProductionEnvironment {
  Youtube,
  None,
}

export const addLinkTagToDocument = (href: string) => {
  let link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  document.head.appendChild(link);
};

export const addScriptToDocument = (src: string) => {
  let s = document.createElement('script');
  s.setAttribute('src', src);
  document.head.appendChild(s);
};

export const containsAd = () => {
  return document.getElementsByClassName('ytp-ad-player-overlay').length > 0;
};

export const addGTag = () => {
  let s = document.createElement('script');
  s.text =
    "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-165372924-1');";
  document.head.appendChild(s);
};

export const unmountApp = () => {
  let reactApp: HTMLElement | null = document.getElementById('MovieNightRoot');
  let divContainer: HTMLElement | null = document.getElementById('MovieNightRoot');

  if (reactApp) {
    console.log('-- React DOM Unmounting');
    ReactDOM.unmountComponentAtNode(reactApp);
  }

  if (divContainer) {
    console.log('-- Removing DIV');
    divContainer.remove();
  }

  store.dispatch({ type: RESET_STORE });

  if (productionEnvironment === ProductionEnvironment.Youtube) {
    document.getElementsByTagName('html')[0].style.fontSize = '10px';
    const youTubeDiv: HTMLElement | null = document.getElementById('content');
    if (youTubeDiv) {
      youTubeDiv.style.width = '';
    }
  }
};

export const getContrastFontColor = (hexColor: string) => {
  hexColor = hexColor.slice(1);
  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};
