import ReduxState from '../../Redux/Types/ReduxState';
import { connect } from 'react-redux';
import MessageInput from '../Components/MessageInput';
import {
  submitMessageActionCreator,
  updateIsTypingActionCreator,
} from '../Actions/MessageInputActions';
import { getIsTypingFromState } from '../Selectors/Selectors';
import { getUsersFromState } from '../../Server/Selectors/ServerSelectors';
import { getGoogleProfileFromState } from '../../GoogleProfile/Selectors/GoogleProfileSelectors';
import { updateAllMessagesActionCreator } from '../../Server/Actions/ServerListenerActions';
import { buildMessageDemoMode } from '../../Server/Types/ServerTypes';

const mapStateToProps = (state: ReduxState) => {
  return {
    isTyping: getIsTypingFromState(state),
    users: getUsersFromState(state),
    myProfile: getGoogleProfileFromState(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    submitMessage: (message: string) => dispatch(submitMessageActionCreator(message)),
    submitMessageDemoMode: (googleID: string | undefined, message: string) =>
      dispatch(updateAllMessagesActionCreator([buildMessageDemoMode(googleID, message)], true)),
    updateIsTyping: (isTyping: boolean) => dispatch(updateIsTypingActionCreator(isTyping)),
  };
};

const MessageInputContainer = connect(mapStateToProps, mapDispatchToProps)(MessageInput);
export default MessageInputContainer;
