import { MovieNightUser } from '../../Server/Types/ServerTypes';
import { MovieNightRoom } from './Room';

// Video Events
export const VIDEO_EVENT = 'Video Event';

export enum VideoEventType {
  Pause,
  Play,
  Seeked,
  CanPlayThrough,
  Buffering,
  Seeking,
}

export interface VideoEvent {
  type: VideoEventType;
  googleID: string | undefined;
  seekTime?: number;
}

export const emitVideoEvent = (
  socket: SocketIOClient.Socket | undefined,
  type: VideoEventType,
  googleID: string | undefined,
  seekTime?: number,
): void => {
  const videoEvent: VideoEvent = {
    type,
    googleID,
    seekTime,
  };

  if (socket && googleID) {
    socket.emit(VIDEO_EVENT, videoEvent);
  }
};

// Room Events
export const ROOM_EVENT = 'Room Event';

export enum RoomEventType {
  CreateRoom,
  CreateRoomSuccess,
  CreateRoomFailure,
  JoinRoom,
  JoinRoomSuccess,
  JoinRoomFailure,
  LeaveRoom,
  LeaveRoomSuccess,
  LeaveRoomFailure,
  RedirectURL,
  ChangeRoomSettings,
  UpdateRoom,
  UpdateRoomError,
}

export interface RoomEvent extends MovieNightUser, Partial<MovieNightRoom> {
  type: RoomEventType;
  roomName: string;
}

export const emitRoomEvent = (
  socket: SocketIOClient.Socket | undefined,
  type: RoomEventType,
  user: MovieNightUser,
  roomName: string,
  movieNightRoom?: MovieNightRoom | undefined,
  url?: string,
): void => {
  let roomEvent: RoomEvent = {
    ...user,
    ...movieNightRoom,
    type,
    roomName,
  };

  if (url) {
    roomEvent = {
      ...roomEvent,
      url,
    };
  }

  if (socket) {
    socket.emit(ROOM_EVENT, roomEvent);
  }
};
