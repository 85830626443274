import { IParticlesParams } from 'react-particles-js';

export const MovieNightID = 'MovieNightID';
export const LoginID = 'LoginID';
export const HeaderID = 'HeaderID';
export const LogoID = 'LogoID';
export const IconID = 'IconID';
export const LottieCelebrationID = 'LottieCelebrationID';
export const LottieCheckMarkID = 'LottieCheckMarkID';
export const LottiePopcornID = 'LottiePopcornID';
export const LottieBackgroundTransitionID = 'LottieBackgroundTransitionID';
export const ParticleID = 'ParticleID';
export const ProfilePictureID = 'ProfilePictureID';
export const MainMenuID = 'MainMenuID';
export const MainMenuNewRoomContainerID = 'MainMenuNewRoomContainerID';
export const MainMenuExistingRoomContainerID = 'MainMenuExistingRoomContainerID';
export const MainMenuShowAllRoomsContainerID = 'MainMenuShowAllRoomsContainerID';
export const MessageHistoryID = 'MessageHistoryID';
export const MessageInputContainerID = 'MessageInputContainerID';
export const MessageInputID = 'MessageInputID';
export const MessageInputTypingContainerID = 'MessageInputTypingContainerID';

export const bubblePreset: IParticlesParams = {
  particles: {
    color: {
      value: '#E50914',
    },
    number: {
      value: 150,
      max: 500,
      density: {
        enable: false,
      },
    },
    opacity: {
      value: 1,
    },
    size: {
      value: 3,
      random: true,
      anim: {
        speed: 4,
        size_min: 0.3,
      },
    },
    line_linked: {
      enable: false,
    },
    move: {
      random: true,
      speed: 1,
      direction: 'top',
      out_mode: 'out',
    },
  },
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: 'bubble',
      },
      onclick: {
        enable: true,
        mode: 'repulse',
      },
    },
    modes: {
      bubble: {
        distance: 100,
        duration: 2,
        size: 1,
        opacity: 15,
      },
      repulse: {
        distance: 100,
        duration: 3,
      },
    },
  },
};

export const bubblePresetWebsite: IParticlesParams = {
  particles: {
    color: {
      value: '#E50914',
    },
    number: {
      value: 50,
      max: 50,
      density: {
        enable: false,
      },
    },
    opacity: {
      value: 1,
    },
    size: {
      value: 2,
      random: true,
      anim: {
        speed: 2,
        size_min: 0.3,
      },
    },
    line_linked: {
      enable: false,
    },
    move: {
      random: true,
      speed: 1,
      direction: 'top',
      out_mode: 'out',
    },
  },
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: 'bubble',
      },
      onclick: {
        enable: true,
        mode: 'repulse',
      },
    },
    modes: {
      bubble: {
        distance: 50,
        duration: 2,
        size: 1,
        opacity: 15,
      },
      repulse: {
        distance: 100,
        duration: 3,
      },
    },
  },
};

export const simplePreset: IParticlesParams = {
  particles: {
    number: {
      value: 50,
    },
    size: {
      value: 3,
    },
  },
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: 'repulse',
      },
    },
  },
};
