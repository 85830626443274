import React from 'react';
import '../Styles/MessageHistory.css';
import MessageGroupContainer from '../Containers/MessageGroupContainer';
import { MessageHistoryID } from '../../MovieNight/Constants/MovieNightConstants';
import { Message } from '../../Server/Types/ServerTypes';
import { GoogleProfile } from '../../GoogleProfile/Types/GoogleProfile';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { transitionDown } from '../../Shared/Utils/SnackbarUtils';
import { MovieNightRoom } from '../../Shared/Types/Room';
import { isMyURLSameAsBaseURL, makeShareURLFromBaseURL } from '../../Shared/Utils/WebsiteURLUtils';

interface MessageHistoryProps {
  messages: Message[];
  myProfile: GoogleProfile | undefined;
  messageInputRef: React.RefObject<HTMLInputElement>;
  room: MovieNightRoom | undefined;
  currentURL: string;
  demoMode?: boolean;
}

interface MessageHistoryState {
  returnToVideoSnackbar: boolean;
}

class MessageHistory extends React.PureComponent<MessageHistoryProps, MessageHistoryState> {
  private messageHistoryRef: React.RefObject<HTMLDivElement>;
  constructor(props: MessageHistoryProps) {
    super(props);

    this.messageHistoryRef = React.createRef();
    this.state = {
      returnToVideoSnackbar: false,
    };
  }

  componentDidMount(): void {
    this.scrollToBottom();
  }

  componentDidUpdate(
    prevProps: Readonly<MessageHistoryProps>,
    prevState: Readonly<{}>,
    snapshot?: any,
  ): void {
    this.scrollToBottom();

    if (this.props.room && !this.props.demoMode) {
      this.setState({
        returnToVideoSnackbar: !isMyURLSameAsBaseURL(
          this.props.room.url,
          window.location.href,
          this.props.room.roomName,
        ),
      });
    }
  }

  onReturnToVideoClick = () => {
    this.setState({ returnToVideoSnackbar: false });
    if (this.props.room) {
      if (
        !isMyURLSameAsBaseURL(this.props.room.url, window.location.href, this.props.room.roomName)
      ) {
        window.location.href = makeShareURLFromBaseURL(
          this.props.room.url,
          this.props.room.roomName,
        );
      }
    }
  };

  returnToVideoSnackbar = () => {
    if (this.props.room) {
      return (
        <Snackbar
          key={17}
          open={this.state.returnToVideoSnackbar}
          onClick={this.onReturnToVideoClick}
          TransitionComponent={transitionDown}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          className={'ReturnToVideoSnackbar SnackbarGeneral'}
        >
          <Alert severity={'warning'} variant={'filled'}>
            Click to return to video
          </Alert>
        </Snackbar>
      );
    }
  };

  getMessageGroups = (messages: Message[]): Message[][] => {
    if (messages.length === 0) {
      return [];
    }

    // Initialize result
    let messageGroups: Message[][] = [];
    messageGroups[0] = [messages[0]];

    // Collect result
    let messageGroupIndex: number = 0;
    let previousID: string = messages[0].googleID;

    for (let i = 1; i < messages.length; i++) {
      if (messages[i].googleID !== previousID) {
        previousID = messages[i].googleID;
        messageGroupIndex += 1;
        messageGroups[messageGroupIndex] = [];
      }
      messageGroups[messageGroupIndex].push(messages[i]);
    }

    return messageGroups;
  };

  scrollToBottom = () => {
    (this.messageHistoryRef.current as HTMLDivElement).scrollTop = (this.messageHistoryRef
      .current as HTMLDivElement).scrollHeight;
  };

  focusMessageInput = (event: React.MouseEvent) => {
    (((this.props.messageInputRef.current as HTMLElement).firstChild as HTMLElement)
      .firstChild as HTMLElement).focus();
  };

  render = () => {
    const messageGroups: Message[][] = this.getMessageGroups(this.props.messages);
    const messageGroupsJSX: JSX.Element[] = messageGroups.map(
      (messageGroup: Message[], index: number) => {
        return <MessageGroupContainer key={index} messages={messageGroup} />;
      },
    );

    return (
      <div
        id={MessageHistoryID}
        className={'MessageHistory ScrollbarMessageHistory'}
        ref={this.messageHistoryRef}
        onClickCapture={this.focusMessageInput}
      >
        {messageGroupsJSX}
        {this.returnToVideoSnackbar()}
      </div>
    );
  };
}

export default MessageHistory;
