import React from 'react';
import { HeaderID, ProfilePictureID, LogoID, IconID } from '../Constants/MovieNightConstants';
import '../Styles/MovieNightHeader.css';
import { GoogleProfile } from '../../GoogleProfile/Types/GoogleProfile';
import { Pulse } from 'react-awesome-reveal';
import { Tooltip } from '@material-ui/core';
import { MovieNightRoom } from '../../Shared/Types/Room';
import { copyURLSnackbar, copyURLToClipboard } from '../../Shared/Utils/WebsiteURLUtils';

interface MovieNightHeaderProps {
  googleProfile: GoogleProfile | undefined;
  isMainMenuActive: boolean;
  isSettingsPaneActive: boolean;
  onChangeSettingsPaneIsActive: (isActive: boolean) => void;
  room: MovieNightRoom | undefined;
  demoMode?: boolean;
}

interface MovieNightHeaderState {
  isMounted: boolean;
  copyClipboardSnackbar: boolean;
}

class MovieNightHeader extends React.PureComponent<MovieNightHeaderProps, MovieNightHeaderState> {
  constructor(props: MovieNightHeaderProps) {
    super(props);

    this.state = {
      isMounted: false,
      copyClipboardSnackbar: false,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<MovieNightHeaderProps>,
    prevState: Readonly<MovieNightHeaderState>,
    snapshot?: any,
  ): void {
    if (
      !prevProps.room &&
      this.props.room &&
      this.props.room.owner === this.props.googleProfile?.googleID
    ) {
      this.copyURLToClipboard();
    }
  }

  componentDidMount(): void {
    this.setState({ isMounted: true });
  }

  renderProfilePicture = (): JSX.Element => {
    if (this.props.googleProfile) {
      return (
        <div
          id={ProfilePictureID}
          onClick={() => this.props.onChangeSettingsPaneIsActive(!this.props.isSettingsPaneActive)}
        >
          <Pulse>
            <Tooltip title={<span className={'MainMenuTooltip'}>Settings</span>} placement="left">
              <img
                src={this.props.googleProfile.imageURL}
                alt={this.props.googleProfile.name}
                height={'40px'}
                width={'40px'}
                className={'HeaderProfilePicture'}
              />
            </Tooltip>
          </Pulse>
        </div>
      );
    }

    return <></>;
  };

  renderIcon = (): JSX.Element => {
    if (this.props.googleProfile) {
      return this.renderProfilePicture();
    }

    return <></>;
  };

  copyURLToClipboard = () => {
    if (this.props.room && !this.props.demoMode) {
      copyURLToClipboard(this.props.room);
      this.setState({ copyClipboardSnackbar: true });
    }
  };

  renderLogo = (): JSX.Element => {
    if (this.state.isMounted) {
      return (
        <Tooltip
          title={<span className={'MainMenuTooltip'}>Click to copy URL</span>}
          disableHoverListener={!this.props.room || this.props.demoMode}
          placement="left"
        >
          <div id={LogoID} className={'HeaderLogoTextStyling'} onClick={this.copyURLToClipboard}>
            <span className={'YouTubeColor'}>Y</span>
            <span>ouTube</span>
            <span className={'YouTubeColor'}> N</span>
            <span>ight</span>
          </div>
        </Tooltip>
      );
    }

    return <></>;
  };

  copyClipboardSnackbar = () => {
    const onClose: () => void = () => this.setState({ copyClipboardSnackbar: false });
    return copyURLSnackbar(this.state.copyClipboardSnackbar, onClose);
  };

  render() {
    return (
      <div id={HeaderID} className={'MovieNightHeader'}>
        {this.renderLogo()}
        <div id={IconID} className={'HeaderIconContainer'}>
          {this.renderIcon()}
        </div>
        {this.copyClipboardSnackbar()}
      </div>
    );
  }
}

export default MovieNightHeader;
