export interface SettingsPaneState {
  isDarkThemeActive: boolean;
  areParticlesDisabled: boolean;
  myColor: string;
  theirColor: string;
}

export const initialSettingsPaneState: SettingsPaneState = {
  isDarkThemeActive: false,
  areParticlesDisabled: false,
  myColor: '#0084ff',
  theirColor: '#e2e2e2',
};
