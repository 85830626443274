import { MovieNightRoom } from '../../Shared/Types/Room';

export interface MainMenuState {
  isMainMenuActive: boolean;
  room: MovieNightRoom | undefined;
  alreadyAutoJoined: boolean;
  publicRooms: MovieNightRoom[];
}

export const initialMainMenuState: MainMenuState = {
  isMainMenuActive: false,
  room: undefined,
  alreadyAutoJoined: false,
  publicRooms: [],
};
