import { Message, MovieNightUser } from './ServerTypes';

export interface ServerState {
  socket: SocketIOClient.Socket | undefined;
  messages: Message[];
  users: { [googleID: string]: MovieNightUser };
  isReady: { [googleID: string]: boolean };
}

export const initialServerState: ServerState = {
  socket: undefined,
  users: {},
  messages: [],
  isReady: {},
};
