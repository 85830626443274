import ReduxState from '../../Redux/Types/ReduxState';
import { connect } from 'react-redux';
import { getUsersFromState } from '../../Server/Selectors/ServerSelectors';
import MessageGroup from '../Components/MessageGroup';
import { getGoogleProfileFromState } from '../../GoogleProfile/Selectors/GoogleProfileSelectors';
import { GoogleProfile } from '../../GoogleProfile/Types/GoogleProfile';
import {
  getIsDarkThemeActiveFromState,
  getMyColorFromState,
  getTheirColorFromState,
} from '../../SettingsPane/Selectors/SettingsPaneSelectors';

const mapStateToProps = (state: ReduxState) => {
  const myProfile: GoogleProfile | undefined = getGoogleProfileFromState(state);
  let myID: string = '';
  if (myProfile) {
    myID = myProfile.googleID;
  }

  return {
    isDarkThemeActive: getIsDarkThemeActiveFromState(state),
    users: getUsersFromState(state),
    myColor: getMyColorFromState(state),
    theirColor: getTheirColorFromState(state),
    myID,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

const MessageGroupContainer = connect(mapStateToProps, mapDispatchToProps)(MessageGroup);
export default MessageGroupContainer;
