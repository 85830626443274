import { MovieNightRoom, YoutubeInfo } from '../Types/Room';
import { createSnackbar } from './SnackbarUtils';

export const getBaseURL = (): string => {
  const URL: string = window.location.href;
  if (URL.includes('youtube.com')) {
    return 'https://www.youtube.com/watch?v=' + getParameterByName('v', URL);
  } else {
    return URL;
  }
};

export const getParameterByName = (name: string, url: string) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const makeShareURLFromBaseURL = (baseURL: string, roomName: string): string => {
  if (baseURL.includes('?')) {
    return baseURL + `&startMovieNightApp=${roomName}`;
  } else {
    return baseURL + `?startMovieNightApp=${roomName}`;
  }
};

export const isMyURLSameAsBaseURL = (baseURL: string, myURL: string, roomName: string): boolean => {
  return (
    baseURL === getBaseURL() ||
    baseURL === myURL ||
    baseURL + `&startMovieNightApp=${roomName}` === myURL ||
    baseURL + `?startMovieNightApp=${roomName}` === myURL
  );
};

export const copyURLToClipboard = (room: MovieNightRoom) => {
  const el = document.createElement('textarea');
  el.value = makeShareURLFromBaseURL(room.url, room.roomName);
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const copyURLSnackbar = (shouldShow: boolean, onClose: () => void) => {
  return createSnackbar(
    'URL Copied!',
    'success',
    2000,
    shouldShow,
    onClose,
    true,
    14,
    'CopyClipboard',
  );
};

export const isNewURLValidURL = (newURL: string): boolean => {
  const videoTag = getParameterByName('v', newURL);

  if (newURL.includes('youtube.com/watch') && videoTag && videoTag.length > 1) {
    console.log('New URL is valid');
    return true;
  }

  return false;
};

export const getYouTubeInfo = (): YoutubeInfo => {
  let titleElement = document.getElementsByClassName(
    'title style-scope ytd-video-primary-info-renderer',
  )[0];
  let title: string = '';

  if (titleElement && titleElement.textContent) {
    title = titleElement.textContent;
  }

  let channel: string = '';
  let uploadInfoElement = document.getElementById('upload-info');
  if (uploadInfoElement) {
    let channelNameElement = uploadInfoElement.getElementsByClassName(
      'yt-simple-endpoint style-scope yt-formatted-string',
    )[0];

    if (channelNameElement && channelNameElement.textContent) {
      channel = channelNameElement.textContent;
    }
  }
  console.log(title);
  console.log(channel);
  return { title, channel };
};
