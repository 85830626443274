import { applyMiddleware, createStore } from 'redux';
import { resetEnhancer, rootReducer } from '../Reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../Sagas/Sagas';

const sagaMiddleware = createSagaMiddleware();

export let store = createStore(
  resetEnhancer(rootReducer),
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);
