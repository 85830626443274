import React from 'react';
import { CardDeck, Card } from 'react-bootstrap';
import FastIcon from '@material-ui/icons/FlashOn';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import ChatIcon from '@material-ui/icons/Textsms';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Particles from 'react-particles-js';
import PaletteIcon from '@material-ui/icons/Palette';
import Emoji from 'react-emoji-render';

import { bubblePresetWebsite } from '../../MovieNight/Constants/MovieNightConstants';
import GithubPicker from 'react-color/lib/components/github/Github';
import { ColorResult } from 'react-color';
import '../../MessageInput/Styles/MessageInput.css';
import { colors } from '../../SettingsPane/Components/SettingsPane';
import Button from 'react-bootstrap/Button';
import { TextField, withStyles } from '@material-ui/core';
import '../Styles/FeatureCardsStyles.css';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

export const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#0e1111',
      fontSize: '16px',
    },
    '& label.Mui-focused': {
      color: '#0084ff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0084ff',
    },
  },
  input: {
    color: 'black',
  },
})(TextField);

interface FeatureCardsProps {}
interface FeatureCardsState {
  isMounted: boolean;
  syncedSnackbar: boolean;
  syncingSnackbar: boolean;
  selectedColor: string;
  darkMode: boolean;
}

class FeatureCards extends React.PureComponent<FeatureCardsProps, FeatureCardsState> {
  constructor(props: FeatureCardsProps) {
    super(props);

    this.state = {
      isMounted: false,
      syncedSnackbar: true,
      syncingSnackbar: true,
      selectedColor: '#000',
      darkMode: false,
    };
  }

  componentDidMount(): void {
    this.setState({ isMounted: true });
  }

  componentDidUpdate(
    prevProps: Readonly<FeatureCardsProps>,
    prevState: Readonly<FeatureCardsState>,
    snapshot?: any,
  ): void {
    if (!prevState.darkMode && this.state.darkMode) {
      let background = document.getElementById('WebsiteLottieBackground');

      if (!!background) {
        background.style.filter = 'brightness(0%)';
      }
    }

    if (prevState.darkMode && !this.state.darkMode) {
      let background = document.getElementById('WebsiteLottieBackground');

      if (!!background) {
        console.log('hey');
        background.style.filter = 'sepia(50%) saturate(200%) brightness(70%) hue-rotate(330deg)';
      }
    }

    if (prevState.syncedSnackbar && !this.state.syncedSnackbar) {
      setTimeout(() => {
        this.setState({ syncedSnackbar: true });
      }, 500);
    }

    if (prevState.syncingSnackbar && !this.state.syncingSnackbar) {
      setTimeout(() => {
        this.setState({ syncingSnackbar: true });
      }, 500);
    }
  }

  getEvolvedChatHeight = () => {
    if (this.state.isMounted) {
      return document.getElementById('EvolvedChat')?.offsetHeight + 'px';
    }

    return '100%';
  };

  syncedSnackbar = (): JSX.Element => {
    return (
      <Card.Text>
        <Collapse in={this.state.syncedSnackbar}>
          <Alert
            severity={'success'}
            variant={'filled'}
            style={{ width: 'fit-content' }}
            onClose={() => this.setState({ syncedSnackbar: !this.state.syncedSnackbar })}
          >
            Synced!
          </Alert>
        </Collapse>
      </Card.Text>
    );
  };

  syncingSnackbar = (): JSX.Element => {
    return (
      <Card.Text>
        <Collapse in={this.state.syncingSnackbar}>
          <Alert
            severity={'warning'}
            variant={'filled'}
            style={{ width: 'fit-content' }}
            onClose={() => this.setState({ syncingSnackbar: false })}
          >
            Syncing...
          </Alert>
        </Collapse>
      </Card.Text>
    );
  };

  render() {
    return (
      <div style={{ fontSize: 'calc(12px + .15vw)', textAlign: 'center' }}>
        <CardDeck>
          <Card>
            <Card.Body style={{ display: 'flex', flexDirection: 'column' }}>
              <Card.Title style={{ fontSize: 'calc(14px + .4vw)', textAlign: 'center' }}>
                <FastIcon style={{ fill: this.state.selectedColor }} />
                Swift Sync
              </Card.Title>
              <Card.Text>
                Synchronize playback with minimal latency and snackbar notifications to keep you
                updated
              </Card.Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexGrow: 1,
                }}
              >
                {this.syncedSnackbar()}
                {this.syncingSnackbar()}
              </div>
            </Card.Body>
          </Card>

          <Card
            id={'EvolvedChat'}
            onClick={() => document.getElementById('WebsiteMessageInputID')?.focus()}
          >
            <div style={{ position: 'absolute', height: '100%' }}>
              <Particles
                params={bubblePresetWebsite}
                height={this.getEvolvedChatHeight()}
                width={'100%'}
              />
            </div>
            <Card.Body>
              <Card.Title>
                <ChatIcon style={{ marginRight: '7px', fill: this.state.selectedColor }} />
                Evolved Chat
              </Card.Title>
              <Card.Text>
                Real-time messaging with profile pictures, typing indicators, emojis, and
                interactive particles
              </Card.Text>
              <Card.Text>
                <div className={'TheirMessageGroupContainer'} style={{ marginLeft: '0' }}>
                  <div
                    className={'TheirMessageGroup'}
                    style={{ background: '#e2e2e2', fontWeight: 'normal' }}
                  >
                    <div className={'TheirTriangle'} style={{ borderTop: `10px solid #e2e2e2` }} />
                    <div className={'MessageElement FirstMessage'}>Hey there!</div>
                  </div>
                  <img className={'ProfilePicture'} src={'Logo.png'} alt={'YouTube Night'} />
                </div>
                <div className={'MyMessageGroupContainer'}>
                  <div
                    className={'MyMessageGroup'}
                    style={{ background: '#0084ff', fontWeight: 'normal' }}
                  >
                    <div className={'MyTriangle'} style={{ borderTop: `10px solid #0084ff` }} />
                    <div className={'MessageElement FirstMessage'}>
                      <Emoji text={'What up :)'} />
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', marginTop: '20px' }}>
                  <div className={'MessageTypingInnerContainer'}>
                    <img
                      className={'MessageTypingPicture'}
                      src={'Logo.png'}
                      alt={'YouTube Night'}
                    />
                  </div>
                  <div className="DotContainer">
                    <div className="Dot1" style={{ background: 'black' }} />
                    <div className="Dot2" style={{ background: 'black' }} />
                    <div className="Dot3" style={{ background: 'black' }} />
                  </div>
                </div>

                <div style={{ marginTop: '5px', marginBottom: '5px' }}>
                  <CustomTextField
                    id={'WebsiteMessageInputID'}
                    className={'WebsiteTypingInput'}
                    placeholder={'Type your message...'}
                    fullWidth={true}
                    autoComplete={'off'}
                  />
                </div>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title>
                <PaletteIcon style={{ marginRight: '5px', fill: this.state.selectedColor }} />
                Custom Design
              </Card.Title>
              <Card.Text>
                Customize the app's colors, disable particles, and turn off the lights while you're
                watching a video
              </Card.Text>
              <Card.Text style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px' }}>
                <GithubPicker
                  triangle={'top-left'}
                  colors={colors}
                  onChangeComplete={(color: ColorResult) => {
                    this.setState({ selectedColor: color.hex });
                  }}
                />
              </Card.Text>
              <Card.Text>
                <div
                  style={{ display: 'inline-block', marginTop: '5px' }}
                  onClick={() => this.setState({ darkMode: !this.state.darkMode })}
                >
                  <Button
                    variant={this.state.darkMode ? 'dark' : 'outline-dark'}
                    className={'NavBarButton'}
                  >
                    {this.state.darkMode ? 'Turn On The Lights' : 'Turn Off The Lights'}
                  </Button>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body style={{ display: 'flex', flexDirection: 'column' }}>
              <Card.Title>
                <PlayArrowIcon style={{ marginRight: '3px', fill: this.state.selectedColor }} />
                Smart Playback
              </Card.Title>
              <Card.Text>
                Stay synced even when the video changes, retain playback control, allow strangers to
                join, and more
              </Card.Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Paper
                  style={{
                    padding: '15px',
                    borderColor: 'black',
                    borderStyle: 'solid',
                    borderWidth: 'thin',
                  }}
                >
                  <Card.Text>
                    <Tooltip
                      title={
                        <span className={'FeatureCardTooltip'}>
                          Create a public or private room!
                        </span>
                      }
                      placement={'left'}
                    >
                      <span className={'SmartPlaybackText'}>Create Room</span>
                    </Tooltip>
                  </Card.Text>
                  <Card.Text>
                    <Tooltip
                      title={
                        <span className={'FeatureCardTooltip'}>
                          Join via URL or via the room name!
                        </span>
                      }
                      placement={'left'}
                    >
                      <span className={'SmartPlaybackText'}>Join Room</span>
                    </Tooltip>
                  </Card.Text>
                  <Card.Text>
                    <Tooltip
                      title={
                        <span className={'FeatureCardTooltip'}>
                          Move between rooms without needing to close the app!
                        </span>
                      }
                      placement={'left'}
                    >
                      <span className={'SmartPlaybackText'}>Leave Room</span>
                    </Tooltip>
                  </Card.Text>
                </Paper>
              </div>
            </Card.Body>
          </Card>
        </CardDeck>
      </div>
    );
  }
}

export default FeatureCards;
