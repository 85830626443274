import ReduxState from '../../Redux/Types/ReduxState';
import { connect } from 'react-redux';
import ServerListener from '../Components/ServerListener';
import {
  updateAllMessagesActionCreator,
  updateAllUsersActionCreator,
  updateIsReadyActionCreator,
} from '../Actions/ServerListenerActions';
import { connectSocketActionCreator } from '../Actions/ServerActions';
import { getGoogleProfileFromState } from '../../GoogleProfile/Selectors/GoogleProfileSelectors';
import { Message, MovieNightUser } from '../Types/ServerTypes';
import {
  setMainMenuActiveIsActiveActionCreator,
  updateMovieNightRoomActionCreator,
  updatePublicRoomsActionCreator,
} from '../../MainMenu/Actions/MainMenuActions';
import {
  changeShouldInitiateAutoLoginActionCreator,
  googleLogInFailureActionActionCreator,
} from '../../GoogleProfile/Modules/LogIn/Actions/GoogleLogInActions';
import { MovieNightRoom } from '../../Shared/Types/Room';
import { setSettingsPaneIsActiveActionCreator } from '../../MovieNight/Actions/MovieNightActions';
import { getUsersFromState } from '../Selectors/ServerSelectors';

const mapStateToProps = (state: ReduxState) => {
  return {
    googleProfile: getGoogleProfileFromState(state),
    users: getUsersFromState(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onConnectSocket: (socket: SocketIOClient.Socket) =>
      dispatch(connectSocketActionCreator(socket)),
    onUpdateAllUsers: (users: { [googleID: string]: MovieNightUser }) =>
      dispatch(updateAllUsersActionCreator(users)),
    onUpdateAllMessages: (messages: Message[]) =>
      dispatch(updateAllMessagesActionCreator(messages, false)),
    onSetMainMenuIsActive: (isActive: boolean) =>
      dispatch(setMainMenuActiveIsActiveActionCreator(isActive)),
    onPreexistingLoginError: () => {
      dispatch(changeShouldInitiateAutoLoginActionCreator(false));
      dispatch(googleLogInFailureActionActionCreator('Pre-existing Login'));
    },
    onUpdateMovieNightRoom: (movieNightRoom: MovieNightRoom | undefined) =>
      dispatch(updateMovieNightRoomActionCreator(movieNightRoom)),
    onChangeSettingsPaneIsActive: (isActive: boolean) =>
      dispatch(setSettingsPaneIsActiveActionCreator(isActive)),
    onUpdateIsReady: (isReady: { [googleID: string]: boolean }) =>
      dispatch(updateIsReadyActionCreator(isReady)),
    onUpdatePublicRooms: (rooms: MovieNightRoom[]) =>
      dispatch(updatePublicRoomsActionCreator(rooms)),
  };
};

const ServerListenerContainer = connect(mapStateToProps, mapDispatchToProps)(ServerListener);
export default ServerListenerContainer;
