export interface MovieNightRoom {
  owner: string | undefined;
  roomName: string;
  isPrivateRoom: boolean;
  keepControl: boolean;
  url: string;
  youtubeInfo: YoutubeInfo;
}

export interface YoutubeInfo {
  title: string;
  channel: string;
}

export const buildMovieNightRoom = (
  owner: string | undefined,
  roomName: string,
  isPrivateRoom: boolean,
  keepControl: boolean,
  url: string,
  youtubeInfo: YoutubeInfo,
): MovieNightRoom => {
  return { owner, roomName, isPrivateRoom, keepControl, url, youtubeInfo };
};
