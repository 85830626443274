import { MovieNightRoom } from '../../Shared/Types/Room';

export const SET_MAIN_MENU_IS_ACTIVE = 'SET_MAIN_MENU_IS_ACTIVE';
export interface SetMainMenuIsActiveAction {
  type: typeof SET_MAIN_MENU_IS_ACTIVE;
  payload: boolean;
}

export const setMainMenuActiveIsActiveActionCreator = (
  isActive: boolean,
): SetMainMenuIsActiveAction => {
  return {
    type: SET_MAIN_MENU_IS_ACTIVE,
    payload: isActive,
  };
};

export const CREATE_MOVIE_NIGHT_ROOM = 'CREATE_MOVIE_NIGHT_ROOM';
export interface CreateMovieNightRoomAction {
  type: typeof CREATE_MOVIE_NIGHT_ROOM;
  payload: MovieNightRoom;
}

export const createMovieNightRoomActionCreator = (
  movieNightRoom: MovieNightRoom,
): CreateMovieNightRoomAction => {
  return {
    type: CREATE_MOVIE_NIGHT_ROOM,
    payload: movieNightRoom,
  };
};

export interface JoinRoomPayload {
  roomName: string;
  url: string;
}

export const makeJoinRoomPayload = (roomName: string, url: string) => {
  return { roomName, url };
};

export const JOIN_MOVIE_NIGHT_ROOM = 'JOIN_MOVIE_NIGHT_ROOM';
export interface JoinMovieNightRoomAction {
  type: typeof JOIN_MOVIE_NIGHT_ROOM;
  payload: JoinRoomPayload;
}

export const joinMovieNightRoomActionCreator = (
  payload: JoinRoomPayload,
): JoinMovieNightRoomAction => {
  return {
    type: JOIN_MOVIE_NIGHT_ROOM,
    payload: payload,
  };
};

export const LEAVE_MOVIE_NIGHT_ROOM = 'LEAVE_MOVIE_NIGHT_ROOM';
export interface LeaveMovieNightRoomAction {
  type: typeof LEAVE_MOVIE_NIGHT_ROOM;
  payload: string;
}

export const leaveMovieNightRoomActionCreator = (roomName: string): LeaveMovieNightRoomAction => {
  return {
    type: LEAVE_MOVIE_NIGHT_ROOM,
    payload: roomName,
  };
};

export const UPDATE_MOVIE_NIGHT_ROOM = 'UPDATE_MOVIE_NIGHT_ROOM';
export interface UpdateMovieNightRoomAction {
  type: typeof UPDATE_MOVIE_NIGHT_ROOM;
  payload: MovieNightRoom | undefined;
}

export const updateMovieNightRoomActionCreator = (
  movieNightRoom: MovieNightRoom | undefined,
): UpdateMovieNightRoomAction => {
  return {
    type: UPDATE_MOVIE_NIGHT_ROOM,
    payload: movieNightRoom,
  };
};

export const CHANGE_AUTO_JOIN = 'CHANGE_AUTO_JOIN';
export interface ChangeAutoJoinAction {
  type: typeof CHANGE_AUTO_JOIN;
  payload: boolean;
}

export const changeAutoJoinActionCreator = (autoJoined: boolean): ChangeAutoJoinAction => {
  return {
    type: CHANGE_AUTO_JOIN,
    payload: autoJoined,
  };
};

export const UPDATE_PUBLIC_ROOMS = 'UPDATE_PUBLIC_ROOMS';
export interface UpdatePublicRoomsAction {
  type: typeof UPDATE_PUBLIC_ROOMS;
  payload: MovieNightRoom[];
}

export const updatePublicRoomsActionCreator = (
  rooms: MovieNightRoom[],
): UpdatePublicRoomsAction => {
  return {
    type: UPDATE_PUBLIC_ROOMS,
    payload: rooms,
  };
};

export type MainMenuActions =
  | SetMainMenuIsActiveAction
  | CreateMovieNightRoomAction
  | JoinMovieNightRoomAction
  | UpdateMovieNightRoomAction
  | LeaveMovieNightRoomAction
  | ChangeAutoJoinAction
  | UpdatePublicRoomsAction;
