import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import '../Styles/NavBarStyles.css';

class NavBar extends React.Component {
  render() {
    return (
      <Navbar id={'MovieNightNavBar'} bg="dark" expand="lg" fixed="top">
        <Navbar.Brand className={'NavBarLogo'}>
          <span className={'YouTubeColor'}>Y</span>
          <span>ouTube</span>
          <span className={'YouTubeColor'}> N</span>
          <span>ight</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className={'justify-content-end'}>
          <Nav>
            <Nav.Link href="#home">
              <a
                href={'https://chrome.google.com/webstore/detail/koojglhfgnffabpnfhhcnilbdagmnapj'}
                target="_blank"
                rel={'noopener noreferrer'}
              >
                <Button variant={'danger'} className={'SupportUsButton'}>
                  Install Extension
                </Button>
              </a>
            </Nav.Link>
            <Nav.Link href="#link">
              <a
                href={'https://www.patreon.com/YouTubeNight'}
                target="_blank"
                rel={'noopener noreferrer'}
              >
                <Button variant={'danger'} className={'InstallButton'}>
                  Support Us on Patreon
                </Button>
              </a>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavBar;
