import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OneIcon from '../Static/One-V2.png';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import TwoIcon from '../Static/Two-V2.png';
import ThreeIcon from '../Static/Three-V2.png';
import FourIcon from '../Static/Four-V2.png';
import FiveIcon from '../Static/Five-V2.png';

class InstructionsPanel extends React.PureComponent {
  render() {
    return (
      <div id={'InstructionsPanel'}>
        <div className={'WebsiteMainTitleText'} style={{ marginTop: '45px', marginBottom: '45px' }}>
          <span className={'YouTubeColor'}>I</span>
          <span>nstructions</span>
        </div>

        <ExpansionPanel className={'WebsiteExpansionPanelContainer'}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <span className={'WebsiteStepsText'}>
              <img src={OneIcon} className={'WebsiteStepsIcon'} alt={'Step One'} />
              Install the extension
            </span>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <ul>
                <li>
                  You can install the extension in your Chrome browser by downloading it from the
                  Chrome Web Store{' '}
                  <a
                    href={
                      'https://chrome.google.com/webstore/detail/koojglhfgnffabpnfhhcnilbdagmnapj'
                    }
                    target="_blank"
                    rel={'noopener noreferrer'}
                    className={'YouTubeLinkColor'}
                  >
                    here
                  </a>
                  .
                </li>
              </ul>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <span className={'WebsiteStepsText'}>
              <img src={TwoIcon} className={'WebsiteStepsIcon'} alt={'Step Two'} />
              Open a video in YouTube
            </span>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <ul>
                <li>Start watching a YouTube video before the extension can be activated.</li>
              </ul>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <span className={'WebsiteStepsText'}>
              <img src={ThreeIcon} className={'WebsiteStepsIcon'} alt={'Step Three'} />
              Create a room and share the URL
            </span>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <ul>
                <li>
                  Click the YouTube Night icon{' '}
                  <img
                    src={'Logo.png'}
                    style={{ height: 'calc(10px + 1.4vw)' }}
                    alt={'YouTube Night Logo'}
                  />{' '}
                  in the top-right of your browser to start the extension.
                </li>
                <li>
                  Sign in with Google so your name and profile picture can be displayed in the chat.
                </li>
                <li>Now you can create or join a room.</li>
                <li>
                  <i>
                    Tip: The extension will open itself up if you copy/paste a friend's share URL
                    into your browser!
                  </i>
                </li>
              </ul>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <span className={'WebsiteStepsText'}>
              <img src={FourIcon} className={'WebsiteStepsIcon'} alt={'Step Four'} />
              Enjoy synced content, even if someone changes the video!
            </span>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <ul>
                <li>Automatic synchronization for playback (playing, pausing, and seeking)</li>
                <li>
                  Automatic synchronization for the currently playing YouTube video (via the URL)
                </li>
                <li>Automatic health checks every 4 seconds (for frame-perfect accuracy)</li>
                <li>
                  <i>
                    Tip: You can search for a video or navigate to the home page without disrupting
                    synchronization or having to create a new room (as long as you don't refresh)!
                    We will simply re-direct everyone to the new video once you find something you
                    like.
                  </i>
                </li>
              </ul>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <span className={'WebsiteStepsText'}>
              <img src={FiveIcon} className={'WebsiteStepsIcon'} alt={'Step Five'} />
              Coming soon - public rooms!
            </span>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <ul>
                <li>
                  In the near future, you'll be able to create public rooms wherein anyone from
                  around the world can watch and chat with you
                </li>
                <li>
                  The list of public rooms (and currently being watched YouTube videos) will be
                  accessible from this website
                </li>
                <li>
                  <i>Note: This is an entirely optional feature, you room is private by default</i>
                </li>
              </ul>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

export default InstructionsPanel;
