import ReduxState from '../../Redux/Types/ReduxState';

export const getIsDarkThemeActiveFromState = (state: ReduxState) => {
  return state.settingsPaneState.isDarkThemeActive;
};

export const getAreParticlesDisabledFromState = (state: ReduxState) => {
  return state.settingsPaneState.areParticlesDisabled;
};

export const getMyColorFromState = (state: ReduxState) => {
  return state.settingsPaneState.myColor;
};

export const getTheirColorFromState = (state: ReduxState) => {
  return state.settingsPaneState.theirColor;
};
