import { combineReducers } from 'redux';
import googleProfileReducer from '../../GoogleProfile/Reducers/GoogleProfileReducer';
import { mainMenuReducer } from '../../MainMenu/Reducers/MainMenuReducer';
import { movieNightReducer } from '../../MovieNight/Reducers/MovieNightReducer';
import { serverReducer } from '../../Server/Reducers/ServerReducer';
import { messageInputReducer } from '../../MessageInput/Reducers/MessageInputReducer';
import { settingsPaneReducer } from '../../SettingsPane/Reducers/SettingsPaneReducer';

export const rootReducer = combineReducers({
  googleProfile: googleProfileReducer,
  mainMenu: mainMenuReducer,
  movieNight: movieNightReducer,
  serverState: serverReducer,
  messageInputState: messageInputReducer,
  settingsPaneState: settingsPaneReducer,
});

export const RESET_STORE = 'RESET_STORE';

export const resetEnhancer = (rootReducer: any) => (state: any, action: any) => {
  if (action.type === RESET_STORE) {
    return rootReducer(undefined, {});
  } else {
    return rootReducer(state, action);
  }
};
