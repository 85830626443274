import { initialMainMenuState, MainMenuState } from '../Types/MainMenuState';
import {
  CHANGE_AUTO_JOIN,
  MainMenuActions,
  SET_MAIN_MENU_IS_ACTIVE,
  UPDATE_MOVIE_NIGHT_ROOM,
  UPDATE_PUBLIC_ROOMS,
} from '../Actions/MainMenuActions';

export const mainMenuReducer = (
  state: MainMenuState = initialMainMenuState,
  action: MainMenuActions,
) => {
  switch (action.type) {
    case UPDATE_MOVIE_NIGHT_ROOM:
      return {
        ...state,
        room: action.payload,
      };

    case SET_MAIN_MENU_IS_ACTIVE:
      return {
        ...state,
        isMainMenuActive: action.payload,
      };

    case CHANGE_AUTO_JOIN:
      return {
        ...state,
        alreadyAutoJoined: action.payload,
      };

    case UPDATE_PUBLIC_ROOMS:
      return {
        ...state,
        publicRooms: action.payload,
      };

    default:
      return state;
  }
};
