import { initialSettingsPaneState, SettingsPaneState } from '../Types/SettingsPaneTypes';
import {
  CHANGE_ARE_PARTICLES_DISABLED,
  CHANGE_IS_DARK_THEME_ACTIVE,
  CHANGE_MY_COLOR,
  CHANGE_THEIR_COLOR,
  SettingsPaneActions,
} from '../Actions/SettingsPaneActions';

export const settingsPaneReducer = (
  state: SettingsPaneState = initialSettingsPaneState,
  action: SettingsPaneActions,
): SettingsPaneState => {
  switch (action.type) {
    case CHANGE_IS_DARK_THEME_ACTIVE:
      return {
        ...state,
        isDarkThemeActive: action.payload,
      };

    case CHANGE_ARE_PARTICLES_DISABLED:
      return {
        ...state,
        areParticlesDisabled: action.payload,
      };

    case CHANGE_MY_COLOR:
      return {
        ...state,
        myColor: action.payload,
      };

    case CHANGE_THEIR_COLOR:
      return {
        ...state,
        theirColor: action.payload,
      };

    default:
      return state;
  }
};
