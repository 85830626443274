import { initialMovieNightState, MovieNightState } from '../Types/MovieNightState';
import {
  CHANGE_CURRENT_URL,
  MovieNightActions,
  SET_APP_HEIGHT,
  SET_SETTINGS_PANE_IS_ACTIVE,
} from '../Actions/MovieNightActions';

export const movieNightReducer = (
  state: MovieNightState = initialMovieNightState,
  action: MovieNightActions,
) => {
  switch (action.type) {
    case SET_APP_HEIGHT:
      return {
        ...state,
        appHeight: action.payload,
      };

    case SET_SETTINGS_PANE_IS_ACTIVE:
      return {
        ...state,
        isSettingsPaneActive: action.payload,
      };

    case CHANGE_CURRENT_URL:
      return {
        ...state,
        currentURL: action.payload.newURL,
      };

    default:
      return state;
  }
};
