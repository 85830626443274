import ReduxState from '../../../../Redux/Types/ReduxState';
import { connect } from 'react-redux';
import GoogleLogOut from '../Components/GoogleLogOut';
import {
  disconnectSocketActionCreator,
  googleLogOutFailureActionActionCreator,
  googleLogOutSuccessActionActionCreator,
} from '../Actions/GoogleLogOutActions';
import { setSettingsPaneIsActiveActionCreator } from '../../../../MovieNight/Actions/MovieNightActions';
import { isDevEnv } from '../../../../index';
import { changeShouldInitiateAutoLoginActionCreator } from '../../LogIn/Actions/GoogleLogInActions';
import { updateMovieNightRoomActionCreator } from '../../../../MainMenu/Actions/MainMenuActions';

const mapStateToProps = (state: ReduxState) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLogoutSuccess: () => {
      if (!isDevEnv) {
      }
      dispatch(changeShouldInitiateAutoLoginActionCreator(false));
      dispatch(googleLogOutSuccessActionActionCreator());
      dispatch(setSettingsPaneIsActiveActionCreator(false));
      dispatch(disconnectSocketActionCreator());
      dispatch(updateMovieNightRoomActionCreator(undefined));
    },
    onLogoutFailure: () => dispatch(googleLogOutFailureActionActionCreator()),
  };
};

const GoogleLogOutContainer = connect(mapStateToProps, mapDispatchToProps)(GoogleLogOut);
export default GoogleLogOutContainer;
