import { all, select, takeEvery } from 'redux-saga/effects';

import {
  SUBMIT_MESSAGE,
  SubmitMessageAction,
  UPDATE_IS_TYPING,
  UpdateIsTypingAction,
} from '../../MessageInput/Actions/MessageInputActions';
import { getSocketFromState } from '../../Server/Selectors/ServerSelectors';
import { MovieNightUser } from '../../Server/Types/ServerTypes';
import { getMovieNightUserFromState } from '../Selectors';
import {
  ADD_MESSAGE_EVENT,
  createAddMessageEvent,
  createUpdateIsTypingEvent,
  UPDATE_IS_TYPING_EVENT,
} from '../../Server/Types/ServerEventTypes';
import {
  CREATE_MOVIE_NIGHT_ROOM,
  CreateMovieNightRoomAction,
  JOIN_MOVIE_NIGHT_ROOM,
  JoinMovieNightRoomAction,
  LEAVE_MOVIE_NIGHT_ROOM,
  LeaveMovieNightRoomAction,
} from '../../MainMenu/Actions/MainMenuActions';
import {
  DISCONNECT_SOCKET,
  DisconnectSocketAction,
} from '../../GoogleProfile/Modules/LogOut/Actions/GoogleLogOutActions';
import { emitRoomEvent, RoomEventType } from '../../Shared/Types/SocketEvents';
import { MovieNightRoom } from '../../Shared/Types/Room';
import {
  CHANGE_KEEP_CONTROL,
  CHANGE_PRIVATE_ROOM,
  ChangeKeepControlAction,
  ChangePrivateRoomAction,
} from '../../SettingsPane/Actions/SettingsPaneActions';
import { getMovieNightRoomFromState } from '../../MainMenu/Selectors/MainMenuSelectors';
import {
  CHANGE_CURRENT_URL,
  ChangeCurrentURLAction,
} from '../../MovieNight/Actions/MovieNightActions';

function* changeCurrentURLSaga(action: ChangeCurrentURLAction) {
  const socket: SocketIOClient.Socket | undefined = yield select(getSocketFromState);
  let room: MovieNightRoom | undefined = yield select(getMovieNightRoomFromState);

  if (room && socket && action.payload.type !== 'Ignore') {
    socket.emit('URL Change', action.payload);
  }
}

function* changeKeepControlSaga(action: ChangeKeepControlAction) {
  const socket: SocketIOClient.Socket = yield select(getSocketFromState);
  const user: MovieNightUser = yield select(getMovieNightUserFromState);
  let room: MovieNightRoom | undefined = yield select(getMovieNightRoomFromState);

  if (room) {
    room = {
      ...room,
      keepControl: action.payload,
    };

    emitRoomEvent(socket, RoomEventType.ChangeRoomSettings, user, room.roomName, room);
  }
}

function* changePrivateRoomSaga(action: ChangePrivateRoomAction) {
  const socket: SocketIOClient.Socket = yield select(getSocketFromState);
  const user: MovieNightUser = yield select(getMovieNightUserFromState);
  let room: MovieNightRoom | undefined = yield select(getMovieNightRoomFromState);

  if (room) {
    room = {
      ...room,
      isPrivateRoom: action.payload,
    };

    emitRoomEvent(socket, RoomEventType.ChangeRoomSettings, user, room.roomName, room);
  }
}

function* leaveRoomSaga(action: LeaveMovieNightRoomAction) {
  const socket: SocketIOClient.Socket = yield select(getSocketFromState);
  const user: MovieNightUser = yield select(getMovieNightUserFromState);
  const roomName: string = action.payload;
  emitRoomEvent(socket, RoomEventType.LeaveRoom, user, roomName, undefined);
}

function* joinRoomSaga(action: JoinMovieNightRoomAction) {
  const socket: SocketIOClient.Socket = yield select(getSocketFromState);
  const user: MovieNightUser = yield select(getMovieNightUserFromState);
  emitRoomEvent(
    socket,
    RoomEventType.JoinRoom,
    user,
    action.payload.roomName,
    undefined,
    action.payload.url,
  );
}

function* createRoomSaga(action: CreateMovieNightRoomAction) {
  const socket: SocketIOClient.Socket = yield select(getSocketFromState);
  const user: MovieNightUser = yield select(getMovieNightUserFromState);
  const room: MovieNightRoom = action.payload;
  console.log('Create Room Saga');
  emitRoomEvent(socket, RoomEventType.CreateRoom, user, room.roomName, room);
}

function* submitMessageSaga(action: SubmitMessageAction) {
  const socket: SocketIOClient.Socket = yield select(getSocketFromState);

  if (socket) {
    const user: MovieNightUser = yield select(getMovieNightUserFromState);
    socket.emit(ADD_MESSAGE_EVENT, createAddMessageEvent(user, action.payload));
  }
}

function* updateIsTypingSaga(action: UpdateIsTypingAction) {
  const socket: SocketIOClient.Socket = yield select(getSocketFromState);

  if (socket) {
    let user: MovieNightUser = yield select(getMovieNightUserFromState);
    user.isTyping = action.payload;
    socket.emit(UPDATE_IS_TYPING_EVENT, createUpdateIsTypingEvent(user));
  }
}

function* disconnectSocketSaga(_action: DisconnectSocketAction) {
  const socket: SocketIOClient.Socket = yield select(getSocketFromState);

  if (socket) {
    socket.disconnect();
  }
}

function* rootSaga() {
  yield all([
    takeEvery(SUBMIT_MESSAGE, submitMessageSaga),
    takeEvery(UPDATE_IS_TYPING, updateIsTypingSaga),
    takeEvery(CREATE_MOVIE_NIGHT_ROOM, createRoomSaga),
    takeEvery(JOIN_MOVIE_NIGHT_ROOM, joinRoomSaga),
    takeEvery(DISCONNECT_SOCKET, disconnectSocketSaga),
    takeEvery(LEAVE_MOVIE_NIGHT_ROOM, leaveRoomSaga),
    takeEvery(CHANGE_PRIVATE_ROOM, changePrivateRoomSaga),
    takeEvery(CHANGE_KEEP_CONTROL, changeKeepControlSaga),
    takeEvery(CHANGE_CURRENT_URL, changeCurrentURLSaga),
  ]);
}

export default rootSaga;
