import { connect } from 'react-redux';
import ReduxState from '../../Redux/Types/ReduxState';
import { getGoogleProfileFromState } from '../../GoogleProfile/Selectors/GoogleProfileSelectors';
import MovieNightHeader from '../Components/MovieNightHeader';
import {
  getMainMenuIsActiveFromState,
  getMovieNightRoomFromState,
} from '../../MainMenu/Selectors/MainMenuSelectors';
import { getSettingsPaneIsActiveFromState } from '../Selectors/MovieNightSelectors';
import { setSettingsPaneIsActiveActionCreator } from '../Actions/MovieNightActions';

const mapStateToProps = (state: ReduxState) => {
  return {
    googleProfile: getGoogleProfileFromState(state),
    isMainMenuActive: getMainMenuIsActiveFromState(state),
    isSettingsPaneActive: getSettingsPaneIsActiveFromState(state),
    room: getMovieNightRoomFromState(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onChangeSettingsPaneIsActive: (isActive: boolean) =>
      dispatch(setSettingsPaneIsActiveActionCreator(isActive)),
  };
};

const MovieNightHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(MovieNightHeader);
export default MovieNightHeaderContainer;
