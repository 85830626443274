// General Events
import { MovieNightUser } from './ServerTypes';

export const PREEXISTING_LOGIN_ERROR = 'Preexisting Login Error';
export const UPDATE_ALL_USERS_EVENT = 'Update All Users';
export const UPDATE_ALL_MESSAGES_EVENT = 'Update All Messages';
export const ADD_MESSAGE_EVENT = 'Add Message Event';
export const IS_VALID_USER_EVENT = 'Is Valid User Event';
export const UPDATE_IS_TYPING_EVENT = 'Update Is Typing Event';
export const UPDATE_IS_READY_EVENT = 'Update Is Ready Event';
export const URL_CHANGE_EVENT = 'URL Change Event';
export const UPDATE_PUBLIC_ROOMS_EVENT = 'Update Public Rooms Event';

export const TYPE_ERROR_EVENT = 'Type Error Event';
export const ROOM_ERROR_EVENT = 'Room Error Event';
export const GOOGLE_ID_ERROR_EVENT = 'Google ID Error Event';
export const SERVER_INFORMATION_ERROR_EVENT = 'Server Information Error Event';

export interface AddMessageEvent extends MovieNightUser {
  message: string;
}

export const createAddMessageEvent = (user: MovieNightUser, message: string): AddMessageEvent => {
  return {
    ...user,
    message: message,
  };
};

export interface UpdateIsTypingEvent extends MovieNightUser {}

export const createUpdateIsTypingEvent = (user: MovieNightUser): UpdateIsTypingEvent => {
  return {
    ...user,
  };
};

export interface UpdateIsTypingEvent extends MovieNightUser {}

export interface URLChangeEvent {
  googleID: string;
  newURL: string;
}
