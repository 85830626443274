import React from 'react';
import {
  MessageInputContainerID,
  MessageInputID,
  MessageInputTypingContainerID,
} from '../../MovieNight/Constants/MovieNightConstants';
import '../Styles/MessageInput.css';
import { TextField, Tooltip, withStyles } from '@material-ui/core';
import { MovieNightUser } from '../../Server/Types/ServerTypes';
import { GoogleProfile } from '../../GoogleProfile/Types/GoogleProfile';
import Patreon from '../../Shared/Constants/Patreon.png';

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#e2e2e2',
      fontSize: '16px',
    },
    '& label.Mui-focused': {
      color: '#0084ff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0084ff',
    },
  },
})(TextField);

interface MessageInputProps {
  submitMessage: (message: string) => void;
  updateIsTyping: (isTyping: boolean) => void;
  isTyping: boolean;
  users: { [googleID: string]: MovieNightUser };
  myProfile: GoogleProfile | undefined;
  messageInputRef: React.RefObject<HTMLInputElement>;
  submitMessageDemoMode: (googleID: string | undefined, message: string) => void;
  demoMode?: boolean;
}

interface MessageInputState {
  message: string;
}

class MessageInput extends React.PureComponent<MessageInputProps, MessageInputState> {
  constructor(props: MessageInputProps) {
    super(props);

    this.state = {
      message: '',
    };
  }

  componentDidMount(): void {
    (((this.props.messageInputRef.current as HTMLElement).firstChild as HTMLElement)
      .firstChild as HTMLElement).focus();
  }

  onMessageInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ message: event.currentTarget.value });

    const isTyping: boolean = event.currentTarget.value !== '';

    if (isTyping !== this.props.isTyping) {
      this.props.updateIsTyping(isTyping);
    }
  };

  onMessageInputSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (this.state.message.length > 0) {
      if (this.props.demoMode) {
        this.props.submitMessageDemoMode(this.props.myProfile?.googleID, this.state.message);
      } else {
        this.props.submitMessage(this.state.message);
      }
      this.props.updateIsTyping(false);
      this.setState({ message: '' });
    }
  };

  getTypingUsers = (): MovieNightUser[] => {
    const users: MovieNightUser[] = [];
    const googleIDs: string[] = Object.keys(this.props.users);
    for (let i = 0; i < googleIDs.length; i++) {
      if (
        this.props.users[googleIDs[i]].isTyping &&
        googleIDs[i] !== this.props.myProfile?.googleID
      ) {
        users.push(this.props.users[googleIDs[i]]);
      }
    }

    return users;
  };

  renderTypingDots = (): JSX.Element => {
    return (
      <div className="DotContainer">
        <div className="Dot1" />
        <div className="Dot2" />
        <div className="Dot3" />
      </div>
    );
  };

  renderTypingJSX = (): JSX.Element => {
    const typingUsers: MovieNightUser[] = this.getTypingUsers();
    return (
      <div className={'MessageTypingInnerContainer'}>
        {typingUsers.map((user) => {
          return (
            <div className={'MessageTypingInnerContainer'}>
              <img
                className={'MessageTypingPicture'}
                src={user.googleProfile?.imageURL}
                alt={user.googleProfile?.name}
              />
            </div>
          );
        })}
        {typingUsers.length > 0 ? this.renderTypingDots() : <></>}
      </div>
    );
  };

  render = () => {
    return (
      <div id={MessageInputContainerID} className={'MessageInputContainer'}>
        <div id={MessageInputTypingContainerID} className={'MessageTypingContainer'}>
          {this.renderTypingJSX()}
        </div>
        <form onSubmit={this.onMessageInputSubmit}>
          <CustomTextField
            id={MessageInputID}
            placeholder={'Type your message...'}
            fullWidth={true}
            onChange={this.onMessageInputChange}
            value={this.state.message}
            autoComplete={'off'}
            ref={this.props.messageInputRef}
          />
        </form>

        <a
          href={'https://www.patreon.com/YouTubeNight'}
          target="_blank"
          rel={'noopener noreferrer'}
          style={{ textAlign: 'center' }}
        >
          <Tooltip
            title={
              <span className={'MainMenuTooltip'}>
                Pls support us on Patreon
                <br />
                All profits go to Feeding America
              </span>
            }
            placement="left"
          >
            <img src={Patreon} className={'PatreonButton'} alt={'Support Us on Patreon'} />
          </Tooltip>
        </a>
      </div>
    );
  };
}

export default MessageInput;
