import { initialServerState, ServerState } from '../Types/ServerState';
import {
  UPDATE_ALL_MESSAGES,
  UPDATE_ALL_USERS,
  UPDATE_IS_READY,
} from '../Actions/ServerListenerActions';
import { CONNECT_SOCKET, ServerActions } from '../Actions/ServerActions';

export const serverReducer = (state: ServerState = initialServerState, action: ServerActions) => {
  switch (action.type) {
    case CONNECT_SOCKET:
      return {
        ...state,
        socket: action.payload,
      };

    case UPDATE_ALL_USERS:
      return {
        ...state,
        users: action.payload,
      };

    case UPDATE_ALL_MESSAGES:
      if (action.payload.demoMode) {
        return {
          ...state,
          messages: [...state.messages, ...action.payload.messages],
        };
      } else {
        return {
          ...state,
          messages: action.payload.messages,
        };
      }

    case UPDATE_IS_READY:
      return {
        ...state,
        isReady: action.payload,
      };

    default:
      return state;
  }
};
