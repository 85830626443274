import React from 'react';
import Lottie from 'react-lottie';
import NavBar from './NavBar';
import MovieNightContainer from '../../MovieNight/Containers/MovieNightContainer';
import 'typeface-roboto';
import '../Styles/MovieNightWebsiteStyles.css';
import Button from 'react-bootstrap/Button';
import { Container } from '@material-ui/core';
import InstructionsPanel from './InstructionsPanel';
import FeatureCards from './FeatureCards';
import Collapse from '@material-ui/core/Collapse';
import AboutCards from './AboutCards';

const animationData = require('../../Lottie/Animations/Background Transition.json');

interface MovieNightWebsiteProps {}

interface MovieNightWebsiteState {
  demoActive: boolean;
  instructionsActive: boolean;
  aboutActive: boolean;
}

class MovieNightWebsite extends React.PureComponent<
  MovieNightWebsiteProps,
  MovieNightWebsiteState
> {
  constructor(props: MovieNightWebsiteProps) {
    super(props);

    this.state = {
      demoActive: false,
      instructionsActive: false,
      aboutActive: false,
    };
  }

  componentDidMount(): void {
    window.addEventListener('resize', () => this.forceUpdate());
    document.body.className += ' ScrollbarWebsite';
    document.body.style.overflowY = 'overlay';
    document.body.style.overflowX = 'hidden';
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', () => this.forceUpdate());
  }

  scrollTo = (id: string, yOffset: number) => {
    const element = document.getElementById(id);

    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  render() {
    const defaultOptions = {
      loop: false,
      autoplay: false,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <div>
        <NavBar />
        {this.state.demoActive ? (
          <MovieNightContainer
            offsetAppFromTop={document.getElementById('MovieNightNavBar')?.offsetHeight + 'px'}
            appRef={React.createRef()}
            demoMode={true}
          />
        ) : (
          <></>
        )}
        <div id={'WebsiteLottieBackground'} className={'WebsiteLottieBackground'}>
          <Lottie
            speed={0.5}
            isStopped={true}
            isClickToPauseDisabled={true}
            options={defaultOptions}
            width={'100vw'}
            height={'100vh'}
          />
        </div>
        <Container style={{ marginBottom: '70px' }}>
          <div className={'WebsiteInstructionsContainer'}>
            <span className={'WebsiteMainTitleTextContainer'}>
              <div className={'WebsiteMainTitleText'}>
                <span className={'YouTubeColor'}>Y</span>
                <span>ouTube</span>
                <span className={'YouTubeColor'}> N</span>
                <span>ight</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  className={'WebsiteButtonContainer'}
                  onClick={() =>
                    this.setState({ instructionsActive: !this.state.instructionsActive })
                  }
                >
                  <Button
                    variant={this.state.instructionsActive ? 'danger' : 'outline-danger'}
                    className={'NavBarButton'}
                    style={{ marginRight: '20px' }}
                  >
                    <span style={{ fontSize: 'calc(12px + .2vw)' }}>Instructions</span>
                  </Button>
                </div>
                <div
                  className={'WebsiteButtonContainer'}
                  onClick={() => this.setState({ demoActive: !this.state.demoActive })}
                  style={{ marginRight: '20px' }}
                >
                  <Button
                    variant={this.state.demoActive ? 'danger' : 'outline-danger'}
                    className={'NavBarButton'}
                  >
                    <span style={{ fontSize: 'calc(12px + .2vw)' }}>Demo</span>
                  </Button>
                </div>
                <div
                  className={'WebsiteButtonContainer'}
                  onClick={() => this.setState({ aboutActive: !this.state.aboutActive })}
                >
                  <Button
                    variant={this.state.aboutActive ? 'danger' : 'outline-danger'}
                    className={'NavBarButton'}
                  >
                    <span style={{ fontSize: 'calc(12px + .2vw)' }}>About</span>
                  </Button>
                </div>
              </div>
              <FeatureCards />
              <Collapse
                in={this.state.instructionsActive}
                timeout={600}
                onEntered={() => this.scrollTo('InstructionsPanel', -100)}
              >
                <InstructionsPanel />
              </Collapse>
              <Collapse
                in={this.state.aboutActive}
                timeout={400}
                onEntered={() => this.scrollTo('AboutCards', -100)}
              >
                <AboutCards />
              </Collapse>
            </span>
          </div>
        </Container>
      </div>
    );
  }
}

export default MovieNightWebsite;
