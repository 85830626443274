import { connect } from 'react-redux';
import ReduxState from '../../Redux/Types/ReduxState';
import MovieNight from '../Components/MovieNight';
import {
  getGoogleProfileFromState,
  getLoggedInStatusFromState,
} from '../../GoogleProfile/Selectors/GoogleProfileSelectors';
import {
  getMainMenuIsActiveFromState,
  getMovieNightRoomFromState,
} from '../../MainMenu/Selectors/MainMenuSelectors';
import { setAppHeightActionCreator } from '../Actions/MovieNightActions';
import {
  getAppHeightFromState,
  getCurrentURLFromState,
  getSettingsPaneIsActiveFromState,
} from '../Selectors/MovieNightSelectors';
import {
  setMainMenuActiveIsActiveActionCreator,
  updateMovieNightRoomActionCreator,
} from '../../MainMenu/Actions/MainMenuActions';
import {
  getAreParticlesDisabledFromState,
  getIsDarkThemeActiveFromState,
} from '../../SettingsPane/Selectors/SettingsPaneSelectors';
import { buildMovieNightRoom } from '../../Shared/Types/Room';

const mapStateToProps = (state: ReduxState) => {
  return {
    isMainMenuActive: getMainMenuIsActiveFromState(state),
    loggedIn: getLoggedInStatusFromState(state),
    googleProfile: getGoogleProfileFromState(state),
    appHeight: getAppHeightFromState(state),
    isSettingsPaneActive: getSettingsPaneIsActiveFromState(state),
    isDarkThemeActive: getIsDarkThemeActiveFromState(state),
    areParticlesDisabled: getAreParticlesDisabledFromState(state),
    room: getMovieNightRoomFromState(state),
    currentURL: getCurrentURLFromState(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateAppHeight: (height: string) => dispatch(setAppHeightActionCreator(height)),
    setMainMenuIsActive: (isActive: boolean) => {
      console.log('i was called');
      dispatch(setMainMenuActiveIsActiveActionCreator(isActive));
    },
    onDemoMode: () => {
      dispatch(
        updateMovieNightRoomActionCreator(
          buildMovieNightRoom('', '', true, false, '', { title: '', channel: '' }),
        ),
      );
      dispatch(setMainMenuActiveIsActiveActionCreator(false));
    },
  };
};

const MovieNightContainer = connect(mapStateToProps, mapDispatchToProps)(MovieNight);
export default MovieNightContainer;
