export const SET_APP_HEIGHT = 'SET_APP_HEIGHT';
export interface SetAppHeightAction {
  type: typeof SET_APP_HEIGHT;
  payload: string;
}

export const setAppHeightActionCreator = (height: string): SetAppHeightAction => {
  return {
    type: SET_APP_HEIGHT,
    payload: height,
  };
};

export const SET_SETTINGS_PANE_IS_ACTIVE = 'SET_SETTINGS_PANE_IS_ACTIVE';
export interface SetSettingsPaneIsActiveAction {
  type: typeof SET_SETTINGS_PANE_IS_ACTIVE;
  payload: boolean;
}

export const setSettingsPaneIsActiveActionCreator = (
  isActive: boolean,
): SetSettingsPaneIsActiveAction => {
  return {
    type: SET_SETTINGS_PANE_IS_ACTIVE,
    payload: isActive,
  };
};

export interface ChangeCurrentURLPayload {
  newURL: string;
  type: string;
  title: string;
  channel: string;
}

export const CHANGE_CURRENT_URL = 'CHANGE_CURRENT_URL';
export interface ChangeCurrentURLAction {
  type: typeof CHANGE_CURRENT_URL;
  payload: ChangeCurrentURLPayload;
}

export const changeCurrentURLAction = (
  newURL: string,
  type: string,
  title: string,
  channel: string,
): ChangeCurrentURLAction => {
  return {
    type: CHANGE_CURRENT_URL,
    payload: { newURL, type, title, channel },
  };
};

export type MovieNightActions =
  | SetAppHeightAction
  | SetSettingsPaneIsActiveAction
  | ChangeCurrentURLAction;
