import { GoogleProfile } from './GoogleProfile';

export interface GoogleProfileState {
  loggedIn: boolean;
  logInError: any;
  logOutError: boolean;
  googleProfile: GoogleProfile | undefined;
  shouldInitiateAutoLogin: boolean;
}

export const initialGoogleProfileState: GoogleProfileState = {
  loggedIn: false,
  logInError: false,
  logOutError: false,
  googleProfile: undefined,
  shouldInitiateAutoLogin: true,
};
