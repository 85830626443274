import { connect } from 'react-redux';
import SettingsPane from '../Components/SettingsPane';
import ReduxState from '../../Redux/Types/ReduxState';
import {
  getAreParticlesDisabledFromState,
  getIsDarkThemeActiveFromState,
  getMyColorFromState,
  getTheirColorFromState,
} from '../Selectors/SettingsPaneSelectors';
import {
  changeAreParticlesDisabledActionCreator,
  changeDarkThemeIsActiveActionCreator,
  changeKeepControlActionCreator,
  changeMyColorActionCreator,
  changePrivateRoomActionCreator,
  changeTheirColorActionCreator,
} from '../Actions/SettingsPaneActions';
import { getMovieNightRoomFromState } from '../../MainMenu/Selectors/MainMenuSelectors';
import { leaveMovieNightRoomActionCreator } from '../../MainMenu/Actions/MainMenuActions';
import { getGoogleProfileFromState } from '../../GoogleProfile/Selectors/GoogleProfileSelectors';

const mapStateToProps = (state: ReduxState) => {
  return {
    isDarkThemeActive: getIsDarkThemeActiveFromState(state),
    areParticlesDisabled: getAreParticlesDisabledFromState(state),
    room: getMovieNightRoomFromState(state),
    myColor: getMyColorFromState(state),
    theirColor: getTheirColorFromState(state),
    myProfile: getGoogleProfileFromState(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onChangeIsDarkThemeActive: (isActive: boolean) => {
      dispatch(changeDarkThemeIsActiveActionCreator(isActive));

      if (isActive) {
        dispatch(changeMyColorActionCreator('#435f7a'));
        dispatch(changeTheirColorActionCreator('#e2e2e2'));
      } else {
        dispatch(changeMyColorActionCreator('#0084ff'));
        dispatch(changeTheirColorActionCreator('#e2e2e2'));
      }
    },
    onChangeAreParticlesDisabled: (areDisabled: boolean) => {
      dispatch(changeAreParticlesDisabledActionCreator(areDisabled));
    },
    onLeaveRoom: (roomName: string) => {
      dispatch(leaveMovieNightRoomActionCreator(roomName));
    },
    onChangeMyColor: (color: string) => {
      dispatch(changeMyColorActionCreator(color));
    },
    onChangeTheirColor: (color: string) => {
      dispatch(changeTheirColorActionCreator(color));
    },
    onPrivateRoomChange: (isPrivateRoom: boolean) => {
      dispatch(changePrivateRoomActionCreator(isPrivateRoom));
    },
    onKeepControlChange: (keepControl: boolean) => {
      dispatch(changeKeepControlActionCreator(keepControl));
    },
  };
};

const SettingsPaneContainer = connect(mapStateToProps, mapDispatchToProps)(SettingsPane);
export default SettingsPaneContainer;
