import ReduxState from '../../Redux/Types/ReduxState';

export const getAppHeightFromState = (state: ReduxState): string => {
  return state.movieNight.appHeight;
};

export const getSettingsPaneIsActiveFromState = (state: ReduxState): boolean => {
  return state.movieNight.isSettingsPaneActive;
};

export const getCurrentURLFromState = (state: ReduxState): string => {
  return state.movieNight.currentURL;
};
