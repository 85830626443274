import { ServerListenerActions } from './ServerListenerActions';

export const CONNECT_SOCKET = 'CONNECT_SOCKET';
export interface ConnectSocketAction {
  type: typeof CONNECT_SOCKET;
  payload: SocketIOClient.Socket;
}

export const connectSocketActionCreator = (socket: SocketIOClient.Socket): ConnectSocketAction => {
  return {
    type: CONNECT_SOCKET,
    payload: socket,
  };
};

export type ServerActions = ServerListenerActions | ConnectSocketAction;
