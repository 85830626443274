import React from 'react';
import '../Styles/MessageGroup.css';
import { Message, MovieNightUser } from '../../Server/Types/ServerTypes';
import { GoogleProfile } from '../../GoogleProfile/Types/GoogleProfile';
import { Tooltip } from '@material-ui/core';
import Emoji from 'react-emoji-render';
import { getContrastFontColor } from '../../Shared/Utils/GeneralUtils';

interface MessageGroupProps {
  myID: string;
  messages: Message[];
  users: { [googleID: string]: MovieNightUser };
  isDarkThemeActive: boolean;
  myColor: string;
  theirColor: string;
}

class MessageGroup extends React.PureComponent<MessageGroupProps> {
  getTime = (date: Date) => {
    let hours: number = date.getHours();
    let minutes: number | string = date.getMinutes();
    let timeOfDay: string = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return hours + ':' + minutes + ' ' + timeOfDay;
  };

  getTooltipTitle = (title: string | undefined): JSX.Element => {
    return <span style={{ fontSize: '13px', fontFamily: 'Inria Sans, sans-serif' }}>{title}</span>;
  };

  getProfilePicture = (isMe: boolean, googleProfile: GoogleProfile | undefined): JSX.Element => {
    if (isMe) {
      return <></>;
    } else {
      return (
        <img className={'ProfilePicture'} src={googleProfile?.imageURL} alt={googleProfile?.name} />
      );
    }
  };

  render() {
    const messages: Message[] = this.props.messages;
    let isMe: boolean = messages[0].googleID === this.props.myID;

    let movieNightUser: MovieNightUser = this.props.users[messages[0].googleID];
    let googleProfile: GoogleProfile | undefined = movieNightUser.googleProfile;
    let color: string = isMe
      ? getContrastFontColor(this.props.myColor)
      : getContrastFontColor(this.props.theirColor);

    const messagesJSX: JSX.Element[] = messages.map((message: Message, index: number) => {
      let classNames: string = 'MessageElement';
      if (message.systemMessage) classNames += ' SystemMessage';
      if (index === 0) classNames += ' FirstMessage';
      if (index === messages.length - 1) classNames += ' LastMessage';
      let time: string = this.getTime(new Date(message.serverTime));

      return (
        <Tooltip key={index} title={this.getTooltipTitle(time)} placement={isMe ? 'left' : 'right'}>
          <div className={classNames} key={index} style={{ color }}>
            <Emoji text={message.message} />
          </div>
        </Tooltip>
      );
    });

    let backgroundStyle: React.CSSProperties = {};
    let triangleStyle: React.CSSProperties = {};
    if (isMe) {
      backgroundStyle = { background: this.props.myColor };
      triangleStyle = { borderTop: `10px solid ${this.props.myColor}` };
    } else {
      backgroundStyle = { background: this.props.theirColor };
      triangleStyle = { borderTop: `10px solid ${this.props.theirColor}` };
    }

    return (
      <div className={isMe ? 'MyMessageGroupContainer' : 'TheirMessageGroupContainer'}>
        <div className={isMe ? 'MyMessageGroup' : 'TheirMessageGroup'} style={backgroundStyle}>
          {isMe ? (
            <div className={'MyTriangle'} style={triangleStyle} />
          ) : (
            <div className={'TheirTriangle'} style={triangleStyle} />
          )}
          {messagesJSX}
        </div>
        <Tooltip
          title={this.getTooltipTitle(googleProfile?.name)}
          placement="left"
          disableHoverListener={isMe}
        >
          {this.getProfilePicture(isMe, googleProfile)}
        </Tooltip>
      </div>
    );
  }
}
export default MessageGroup;
