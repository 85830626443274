export const GOOGLE_LOG_OUT_SUCCESS = 'GOOGLE_LOG_OUT_SUCCESS';
export interface GoogleLogOutSuccessAction {
  type: typeof GOOGLE_LOG_OUT_SUCCESS;
}

export const googleLogOutSuccessActionActionCreator = (): GoogleLogOutSuccessAction => {
  return { type: GOOGLE_LOG_OUT_SUCCESS };
};

export const GOOGLE_LOG_OUT_FAILURE = 'GOOGLE_LOG_OUT_FAILURE';
export interface GoogleLogOutFailureAction {
  type: typeof GOOGLE_LOG_OUT_FAILURE;
}

export const googleLogOutFailureActionActionCreator = (): GoogleLogOutFailureAction => {
  return { type: GOOGLE_LOG_OUT_FAILURE };
};

export const DISCONNECT_SOCKET = 'DISCONNECT_SOCKET';
export interface DisconnectSocketAction {
  type: typeof DISCONNECT_SOCKET;
}
export const disconnectSocketActionCreator = (): DisconnectSocketAction => {
  return { type: DISCONNECT_SOCKET };
};

export type GoogleLogOutActions = GoogleLogOutSuccessAction | GoogleLogOutFailureAction;
