import React from 'react';
import '../Styles/MovieNight.css';
import GoogleLogInContainer from '../../GoogleProfile/Modules/LogIn/Containers/GoogleLogInContainer';
import { GoogleProfile } from '../../GoogleProfile/Types/GoogleProfile';
import {
  bubblePreset,
  LoginID,
  LottieBackgroundTransitionID,
  LottieCelebrationID,
  LottiePopcornID,
  MovieNightID,
  ParticleID,
} from '../Constants/MovieNightConstants';
import MovieNightHeaderContainer from '../Containers/MovieNightHeaderContainer';
import Particles from 'react-particles-js';
import Lottie from 'react-lottie';
import { buildLottieEventListener, buildLottieOptions } from '../../Lottie/Utils/LottieUtils';
import MainMenuContainer from '../../MainMenu/Containers/MainMenuContainer';
import ServerListenerContainer from '../../Server/Containers/ServerListenerContainer';
import MessageHistoryContainer from '../../MessageHistory/Containers/MessageHistoryContainer';
import MessageInputContainer from '../../MessageInput/Containers/MessageInputContainer';
import VideoListenerContainer from '../../VideoListener/Containers/VideoListenerContainer';
import 'bootstrap/dist/css/bootstrap.css';
import SettingsPaneContainer from '../../SettingsPane/Containers/SettingsPaneContainer';
import { MovieNightRoom } from '../../Shared/Types/Room';
import { isMyURLSameAsBaseURL } from '../../Shared/Utils/WebsiteURLUtils';

const celebrationAnimation = require('../../Lottie/Animations/Celebration 2.json');
const popcornAnimation = require('../../Lottie/Animations/Popcorn Introduction.json');

const backgroundTransitionAnimation = require('../../Lottie/Animations/Background Transition.json');

interface MovieNightProps {
  loggedIn: boolean;
  isMainMenuActive: boolean;
  googleProfile: GoogleProfile | undefined;
  updateAppHeight: (height: string) => void;
  appHeight: string;
  offsetAppFromTop: string;
  setMainMenuIsActive: (isActive: boolean) => void;
  appRef: React.RefObject<HTMLDivElement>;
  isSettingsPaneActive: boolean;
  isDarkThemeActive: boolean;
  areParticlesDisabled: boolean;
  room: MovieNightRoom | undefined;
  currentURL: string;
  demoMode?: boolean;
  onDemoMode: () => void;
}

interface MovieNightState {
  isMounted: boolean;
  loginWarningSnackbarIsActive: boolean;
  shouldRenderCelebrationAnimation: boolean;
  shouldRenderMessageHistory: boolean;
}

class MovieNight extends React.PureComponent<MovieNightProps, MovieNightState> {
  messageInputRef: React.RefObject<HTMLInputElement>;
  constructor(props: MovieNightProps) {
    super(props);

    this.state = {
      isMounted: false,
      shouldRenderCelebrationAnimation: false,
      loginWarningSnackbarIsActive: false,
      shouldRenderMessageHistory: false,
    };

    this.messageInputRef = React.createRef();
  }

  componentDidMount(): void {
    this.forceUpdate();
    this.setState({ isMounted: true });
    this.props.updateAppHeight(
      this.props.appRef.current ? this.props.appRef.current.clientHeight + 'px' : '100%',
    );

    if (this.props.isDarkThemeActive) {
      this.setState({ shouldRenderMessageHistory: true });
    }
    console.log('MOUNTEDDD');
    if (this.props.demoMode) {
      this.props.onDemoMode();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<MovieNightProps>,
    prevState: Readonly<MovieNightState>,
    snapshot?: any,
  ): void {
    if (!prevProps.loggedIn && this.props.loggedIn) {
      if (!this.props.demoMode) {
        this.setState({ shouldRenderCelebrationAnimation: true });
      }

      if (!this.props.demoMode) {
        this.props.setMainMenuIsActive(true);
      }

      if (this.props.demoMode) {
        this.props.onDemoMode();
      }
    }

    if (!prevProps.isDarkThemeActive && this.props.isDarkThemeActive) {
      this.setState({ shouldRenderMessageHistory: true });
    }
  }

  renderLogIn = (): JSX.Element => {
    if (!this.props.loggedIn) {
      return (
        <div id={LoginID}>
          <GoogleLogInContainer demoMode={this.props.demoMode} />
        </div>
      );
    }

    return <></>;
  };

  renderCelebrationAnimation = (): JSX.Element => {
    if (this.state.shouldRenderCelebrationAnimation) {
      return (
        <div id={LottieCelebrationID} className={'AnimationEffects'}>
          <Lottie
            options={buildLottieOptions(celebrationAnimation, false)}
            height={
              this.props.appRef.current ? this.props.appRef.current.clientHeight + 'px' : '100%'
            }
          />
        </div>
      );
    }

    return <></>;
  };

  focusMessageInput = () => {
    if (
      this.messageInputRef.current &&
      this.messageInputRef.current.firstChild &&
      this.messageInputRef.current.firstChild.firstChild
    ) {
      (this.messageInputRef.current.firstChild.firstChild as HTMLElement).focus();
    }
  };

  renderParticleBackground = (): JSX.Element => {
    if (this.props.areParticlesDisabled) {
      return <></>;
    }

    return (
      <div id={ParticleID} className={'AnimationEffects'} onClick={this.focusMessageInput}>
        <Particles
          height={
            this.props.appRef.current ? this.props.appRef.current.clientHeight - 5 + 'px' : '100%'
          }
          params={bubblePreset}
        />
      </div>
    );
  };

  renderMessageInput = (): JSX.Element => {
    if (
      this.state.shouldRenderMessageHistory &&
      this.props.loggedIn &&
      !this.props.isMainMenuActive &&
      !this.props.isSettingsPaneActive
    ) {
      return (
        <MessageInputContainer
          messageInputRef={this.messageInputRef}
          demoMode={this.props.demoMode}
        />
      );
    }

    return <></>;
  };

  renderMessageHistory = (): JSX.Element => {
    if (
      this.props.loggedIn &&
      !this.props.isSettingsPaneActive &&
      !this.props.isMainMenuActive &&
      this.state.shouldRenderMessageHistory
    ) {
      return (
        <MessageHistoryContainer
          messageInputRef={this.messageInputRef}
          demoMode={this.props.demoMode}
        />
      );
    }
    return <></>;
  };

  renderPopcornIntro = (): JSX.Element => {
    if (!this.props.loggedIn) {
      return (
        <div id={LottiePopcornID} className={'PopcornAnimation'}>
          <Lottie
            options={buildLottieOptions(popcornAnimation, true)}
            width={'300px'}
            height={'300px'}
          />
        </div>
      );
    }

    return <></>;
  };

  onBackgroundTransitionComplete = () => {
    if (!this.props.isMainMenuActive) {
      this.setState({
        shouldRenderMessageHistory: true,
      });
    }
  };

  renderBackgroundTransition = (): JSX.Element => {
    if (this.props.isDarkThemeActive) {
      return <></>;
    }

    let direction: number = 2;
    let speed: number = 1;
    if (this.props.loggedIn && !this.props.isMainMenuActive) {
      direction = -1;
      speed = 2.5;
    }

    if (!this.state.shouldRenderMessageHistory) {
      return (
        <div
          id={LottieBackgroundTransitionID}
          className={'AnimationEffectsMainMenu'}
          style={{ marginTop: this.props.offsetAppFromTop }}
        >
          <Lottie
            direction={direction}
            speed={speed}
            isPaused={!this.props.loggedIn}
            options={buildLottieOptions(backgroundTransitionAnimation, false, true)}
            height={
              parseInt(this.props.appHeight.substring(0, this.props.appHeight.length - 2)) -
              65 +
              'px'
            }
            eventListeners={[
              buildLottieEventListener('complete', this.onBackgroundTransitionComplete),
            ]}
            width={'300px'}
          />
        </div>
      );
    } else {
      return (
        <div
          id={LottieBackgroundTransitionID}
          className={'AnimationEffectsMainMenu'}
          style={{ marginTop: this.props.offsetAppFromTop }}
        >
          <Lottie
            isPaused={true}
            options={buildLottieOptions(backgroundTransitionAnimation, false, false)}
            height={
              parseInt(this.props.appHeight.substring(0, this.props.appHeight.length - 2)) -
              65 +
              'px'
            }
            width={'300px'}
          />
        </div>
      );
    }
  };

  renderMainMenu = (): JSX.Element => {
    if (this.props.isMainMenuActive && this.props.loggedIn && !this.props.isSettingsPaneActive) {
      return <MainMenuContainer />;
    }

    return <></>;
  };

  renderSettingsPane = (): JSX.Element => {
    if (this.props.isSettingsPaneActive && this.props.loggedIn) {
      return <SettingsPaneContainer demoMode={this.props.demoMode} />;
    }

    return <></>;
  };

  communicateWithServer = (): JSX.Element => {
    if (this.props.loggedIn) {
      return <ServerListenerContainer demoMode={this.props.demoMode} />;
    }

    return <></>;
  };

  listenForVideoEvents = (): JSX.Element => {
    if (!this.props.demoMode) {
      console.log(this.props.currentURL);
      console.log('Checking conditions');
      console.log('Valid URL? ');
      if (this.props.room) {
        console.log(
          isMyURLSameAsBaseURL(this.props.room.url, window.location.href, this.props.room.roomName)
            ? 'Yes'
            : 'No',
        );
      }

      if (
        this.props.loggedIn &&
        !this.props.isMainMenuActive &&
        this.props.room &&
        isMyURLSameAsBaseURL(this.props.room.url, window.location.href, this.props.room.roomName) &&
        !!document.getElementsByTagName('video')[0]
      ) {
        return <VideoListenerContainer />;
      }
    }
    return <></>;
  };

  render = () => {
    return (
      <div
        id={MovieNightID}
        className={'MovieNight'}
        ref={this.props.appRef}
        style={{ marginTop: this.props.offsetAppFromTop }}
      >
        {this.renderCelebrationAnimation()}
        {this.renderParticleBackground()}
        {this.renderBackgroundTransition()}
        <MovieNightHeaderContainer demoMode={this.props.demoMode} />
        {this.renderPopcornIntro()}
        {this.renderLogIn()}
        {this.renderMainMenu()}
        {this.renderSettingsPane()}
        {this.renderMessageHistory()}
        {this.renderMessageInput()}
        {this.communicateWithServer()}
        {this.listenForVideoEvents()}
      </div>
    );
  };
}

export default MovieNight;
