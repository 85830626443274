import React from 'react';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { googleClientID, loginText } from '../../../Constants/GoogleConstants';
import { buildGoogleProfile, GoogleProfile } from '../../../Types/GoogleProfile';
import GoogleButton from 'react-google-button';
import { isDevEnv } from '../../../../index';
import '../../../Styles/GoogleProfile.css';
import { MovieNightUser } from '../../../../Server/Types/ServerTypes';

interface GoogleLogInProps {
  onSuccess: (googleProfile: GoogleProfile) => void;
  onFailure: (error: any) => void;
  shouldInitiateAutoLogin: boolean;
  demoMode?: boolean;
  onDemoMode: (users: { [googleID: string]: MovieNightUser }) => void;
}

class GoogleLogIn extends React.PureComponent<GoogleLogInProps> {
  componentDidMount(): void {
    if (this.props.demoMode) {
      const demoClientGoogleProfile: GoogleProfile = buildGoogleProfile(
        'DemoClientID',
        'settings.png',
        'YouTube Night',
        'YouTube',
        'Night',
      );
      const demoServerGoogleProfile: GoogleProfile = buildGoogleProfile(
        'DemoServerID',
        'Logo.png',
        'YouTube Night',
        'YouTube',
        'Night',
      );
      const sharedMovieNightUser = {
        isTyping: false,
        room: {
          owner: '',
          roomName: '',
          isPrivateRoom: true,
          keepControl: false,
          url: '',
          youtubeInfo: {
            title: '',
            channel: '',
          },
        },
      };

      const demoClientUser: MovieNightUser = {
        googleProfile: demoClientGoogleProfile,
        ...sharedMovieNightUser,
      };
      const demoServerUser: MovieNightUser = {
        googleProfile: demoServerGoogleProfile,
        ...sharedMovieNightUser,
        isTyping: false,
      };

      this.props.onSuccess(demoClientGoogleProfile);
      this.props.onDemoMode({ DemoClientID: demoClientUser, DemoServerID: demoServerUser });
    } else if (isDevEnv) {
      // const imageURLs = [
      //   'http://clipart-library.com/data_images/320469.png',
      //   'http://clipart-library.com/data_images/320474.png',
      //   'http://clipart-library.com/data_images/320465.png',
      //   'http://clipart-library.com/data_images/320507.jpg',
      // ];
      const randomIDAndName: string = (Math.random() * 10).toString();
      this.props.onSuccess(
        buildGoogleProfile(
          randomIDAndName,
          'http://clipart-library.com/data_images/320507.jpg',
          // imageURLs[Math.floor(Math.random() * imageURLs.length)],
          randomIDAndName,
          'Saavan',
          'Nanavati',
        ),
      );
    }

    // Production Env
    else {
      if (this.props.shouldInitiateAutoLogin) {
        this.initiateProductionEnvLogIn();
      }
    }
  }

  onDevSuccess = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('profileObj' in response) {
      this.props.onSuccess(
        buildGoogleProfile(
          response.profileObj.googleId,
          response.profileObj.imageUrl,
          response.profileObj.name,
          response.profileObj.givenName,
          response.profileObj.familyName,
        ),
      );
    } else if ('code' in response) {
      this.props.onFailure(response.code);
    }
  };

  onDevFailure = (error: any) => {
    this.props.onFailure(error);
  };

  initiateProductionEnvLogIn = () => {
    const { onSuccess, onFailure } = this.props;
    console.log('Initiating Production Login');
    chrome.runtime.sendMessage({ type: 'Login', mode: 'Cache' }, (response: string) => {
      console.log(response);
      const googleProfile: any = JSON.parse(response);
      if (googleProfile.hasOwnProperty('id')) {
        onSuccess(
          buildGoogleProfile(
            googleProfile.id,
            googleProfile.picture,
            googleProfile.name,
            googleProfile.given_name,
            googleProfile.family_name,
          ),
        );
      } else {
        onFailure(response);
      }
    });
  };

  render = () => {
    if (isDevEnv) {
      return (
        <div className={'DevLogInButton'}>
          <GoogleLogin
            clientId={googleClientID}
            buttonText={loginText}
            fetchBasicProfile={true}
            onSuccess={this.onDevSuccess}
            onFailure={this.onDevFailure}
          />
        </div>
      );
    } else {
      return (
        <div className={'ProductionLogInButton ProductionGoogleButton'}>
          <GoogleButton label={'Sign In'} type={'dark'} onClick={this.initiateProductionEnvLogIn} />
        </div>
      );
    }
  };
}

export default GoogleLogIn;
