import { initialMessageInputState, MessageInputState } from '../Types/MessageInputState';
import {
  MessageInputActions,
  SUBMIT_MESSAGE,
  UPDATE_IS_TYPING,
} from '../Actions/MessageInputActions';

export const messageInputReducer = (
  state: MessageInputState = initialMessageInputState,
  action: MessageInputActions,
) => {
  switch (action.type) {
    case SUBMIT_MESSAGE:
      return {
        ...state,
        isSending: true,
        messagesSending: [...state.messagesSending, action.payload],
      };

    case UPDATE_IS_TYPING:
      return {
        ...state,
        isTyping: action.payload,
      };

    default:
      return state;
  }
};
