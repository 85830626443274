export interface GoogleProfile {
  googleID: string;
  imageURL: string;
  name: string;
  givenName: string;
  familyName: string;
}

export const buildGoogleProfile = (
  googleID: string,
  imageURL: string,
  name: string,
  givenName: string,
  familyName: string,
): GoogleProfile => {
  return {
    googleID,
    imageURL,
    name,
    givenName,
    familyName,
  };
};
