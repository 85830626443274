import React from 'react';
import '../Styles/SettingsPane.css';
import GoogleLogOutContainer from '../../GoogleProfile/Modules/LogOut/Containers/GoogleLogOutContainer';
import { Tooltip } from '@material-ui/core';
import BootstrapButton from 'react-bootstrap/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Button from '@material-ui/core/Button';
import { MovieNightRoom } from '../../Shared/Types/Room';
import { productionEnvironment } from '../../index';
import { getContrastFontColor, ProductionEnvironment } from '../../Shared/Utils/GeneralUtils';
import { ColorResult } from 'react-color';
import GithubPicker from 'react-color/lib/components/github/Github';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Pulse } from 'react-awesome-reveal';
import { GoogleProfile } from '../../GoogleProfile/Types/GoogleProfile';
import LinkIcon from '@material-ui/icons/Link';
import { copyURLSnackbar, copyURLToClipboard } from '../../Shared/Utils/WebsiteURLUtils';
import Nav from 'react-bootstrap/Nav';

export const colors = [
  '#000',
  '#B80000',
  '#DB3E00',
  '#FCCB00',
  '#008B02',
  '#006B76',
  '#0084ff',
  '#1273DE',
  '#004DCF',
  '#5300EB',
  '#EB9694',
  '#FAD0C3',
  '#FEF3BD',
  '#C1E1C5',
  '#BEDADC',
  '#C4DEF6',
  '#BED3F3',
  '#D4C4FB',
  '#e2e2e2',
  '#f0f0f0',
  '#ffffff',
];

interface SettingsPaneProps {
  isDarkThemeActive: boolean;
  onChangeIsDarkThemeActive: (isActive: boolean) => void;
  areParticlesDisabled: boolean;
  onChangeAreParticlesDisabled: (areDisabled: boolean) => void;
  onLeaveRoom: (roomName: string) => void;
  room: MovieNightRoom | undefined;
  myProfile: GoogleProfile | undefined;
  myColor: string;
  onChangeMyColor: (color: string) => void;
  theirColor: string;
  onChangeTheirColor: (color: string) => void;
  onPrivateRoomChange: (isPrivateRoom: boolean) => void;
  onKeepControlChange: (keepControl: boolean) => void;
  demoMode?: boolean;
}

interface SettingsPaneState {
  myColor: string;
  theirColor: string;
  myColorSelected: boolean;
  copyClipboardSnackbar: boolean;
}

class SettingsPane extends React.PureComponent<SettingsPaneProps, SettingsPaneState> {
  constructor(props: SettingsPaneProps) {
    super(props);

    this.state = {
      myColor: this.props.myColor,
      theirColor: this.props.theirColor,
      myColorSelected: true,
      copyClipboardSnackbar: false,
    };
  }

  componentDidMount(): void {
    if (this.props.isDarkThemeActive) this.updateYouTubeBackground('black', '#e2e2e2');
    else this.updateYouTubeBackground('transparent', 'black');
  }

  componentDidUpdate(
    prevProps: Readonly<SettingsPaneProps>,
    prevState: Readonly<SettingsPaneState>,
    snapshot?: any,
  ): void {
    if (productionEnvironment === ProductionEnvironment.Youtube) {
      if (!prevProps.isDarkThemeActive && this.props.isDarkThemeActive)
        this.updateYouTubeBackground('black', '#e2e2e2');

      if (prevProps.isDarkThemeActive && !this.props.isDarkThemeActive)
        this.updateYouTubeBackground('transparent', 'black');
    }

    if (prevProps.myColor !== this.props.myColor) this.setState({ myColor: this.props.myColor });

    if (prevProps.theirColor !== this.props.theirColor)
      this.setState({ theirColor: this.props.theirColor });
  }

  updateYouTubeBackground = (backgroundColor: string, fontColor: string) => {
    let appDiv = document.getElementsByTagName('ytd-app');
    let playlistHeader = document.getElementsByClassName(
      'header style-scope ytd-playlist-panel-renderer',
    );
    let playlistItems = document.getElementsByClassName(
      'playlist-items yt-scrollbar-dark style-scope ytd-playlist-panel-renderer',
    );

    let backgroundColorElements = [appDiv, playlistHeader, playlistItems];
    for (let i = 0; i < backgroundColorElements.length; i++) {
      for (let j = 0; j < backgroundColorElements[i].length; j++) {
        if (backgroundColorElements[i][j]) {
          (backgroundColorElements[i][j] as HTMLElement).style.background = backgroundColor;
        }
      }
    }

    let comments = document.getElementsByClassName('style-scope ytd-comment-renderer');
    let fontColorElements = [comments];

    for (let i = 0; i < fontColorElements.length; i++) {
      for (let j = 0; j < fontColorElements[i].length; j++) {
        if (fontColorElements[i][j]) {
          (fontColorElements[i][j] as HTMLElement).style.color = fontColor;
        }
      }
    }
  };

  toggleBackground = () => {
    const tooltip: string = 'Removes the colorful backsplash';

    return (
      <div className={'SettingsOption'}>
        <Tooltip title={<span className={'MainMenuTooltip'}>{tooltip}</span>} placement="left">
          <BootstrapButton
            variant={this.props.isDarkThemeActive ? 'primary' : 'outline-primary'}
            onClick={() => this.props.onChangeIsDarkThemeActive(!this.props.isDarkThemeActive)}
          >
            Dark Theme
          </BootstrapButton>
        </Tooltip>
      </div>
    );
  };

  toggleParticles = () => {
    const tooltip: string = 'Removes the moving particles';

    return (
      <div className={'SettingsOption'}>
        <Tooltip title={<span className={'MainMenuTooltip'}>{tooltip}</span>} placement="left">
          <BootstrapButton
            variant={this.props.areParticlesDisabled ? 'primary' : 'outline-primary'}
            onClick={() =>
              this.props.onChangeAreParticlesDisabled(!this.props.areParticlesDisabled)
            }
          >
            Disable Particles
          </BootstrapButton>
        </Tooltip>
      </div>
    );
  };

  leaveRoomButton = () => {
    if (this.props.room) {
      return (
        <div className={'SettingsOption LeaveRoomButton'}>
          <Button
            variant="outlined"
            color="secondary"
            disabled={this.props.demoMode}
            startIcon={<ExitToAppIcon />}
            onClick={() => this.props.onLeaveRoom((this.props.room as MovieNightRoom).roomName)}
          >
            Leave Room
          </Button>
        </div>
      );
    }

    return <></>;
  };

  myColorButton = () => {
    return (
      <BootstrapButton
        variant={this.props.areParticlesDisabled ? 'info' : 'outline-info'}
        style={{
          background: this.state.myColor,
          borderColor: this.state.myColor,
          color: getContrastFontColor(this.state.myColor),
          boxShadow: 'none',
        }}
        onClick={() => this.setState({ myColorSelected: true })}
      >
        My Color
      </BootstrapButton>
    );
  };

  theirColorButton = () => {
    return (
      <BootstrapButton
        active={true}
        variant={this.props.areParticlesDisabled ? 'info' : 'outline-info'}
        style={{
          background: this.state.theirColor,
          borderColor: this.state.theirColor,
          color: getContrastFontColor(this.state.theirColor),
          boxShadow: 'none',
        }}
        onClick={() => this.setState({ myColorSelected: false })}
      >
        Their Color
      </BootstrapButton>
    );
  };

  colorButtons = () => {
    return (
      <div className={'SettingsOption'}>
        <ButtonGroup className="mr-2" aria-label="First group">
          {this.state.myColorSelected ? (
            <Pulse>{this.myColorButton()}</Pulse>
          ) : (
            this.myColorButton()
          )}
          {!this.state.myColorSelected ? (
            <Pulse>{this.theirColorButton()}</Pulse>
          ) : (
            this.theirColorButton()
          )}
        </ButtonGroup>
      </div>
    );
  };

  colorPicker = () => {
    return (
      <div className={'SettingsOption ColorPickerGithub'}>
        <GithubPicker
          width={'200px'}
          triangle={this.state.myColorSelected ? 'top-left' : 'top-right'}
          colors={colors}
          onChangeComplete={(color: ColorResult) => {
            if (this.state.myColorSelected) {
              this.setState({ myColor: color.hex });
              this.props.onChangeMyColor(color.hex);
            } else {
              this.setState({ theirColor: color.hex });
              this.props.onChangeTheirColor(color.hex);
            }
          }}
        />
      </div>
    );
  };

  // Room settings below
  isRoomOwner = () => {
    if (this.props.room) {
      return this.props.room.owner === this.props.myProfile?.googleID;
    }

    return false;
  };

  togglePrivateRoom = () => {
    if (this.props.room) {
      const tooltip: string = this.props.room.isPrivateRoom
        ? 'Your room is currently private (unlisted)'
        : 'Your room is currently public (listed)';

      return (
        <div className={'SettingsOption'}>
          <Tooltip title={<span className={'MainMenuTooltip'}>{tooltip}</span>} placement="left">
            <div>
              <BootstrapButton
                variant={this.props.room.isPrivateRoom ? 'primary' : 'outline-primary'}
                onClick={() => this.props.onPrivateRoomChange(!this.props.room?.isPrivateRoom)}
                disabled={!this.isRoomOwner()}
              >
                Private Room
              </BootstrapButton>
            </div>
          </Tooltip>
        </div>
      );
    }
  };

  toggleKeepControl = () => {
    if (this.props.room) {
      const tooltip: string = this.props.room.keepControl
        ? '(Beta) Currently, you have playback control but anyone can pick the URL'
        : '(Recommended) Currently, anyone can play, pause, or seek';

      return (
        <div className={'SettingsOption'}>
          <Tooltip title={<span className={'MainMenuTooltip'}>{tooltip}</span>} placement="left">
            <div>
              <BootstrapButton
                variant={this.props.room.keepControl ? 'primary' : 'outline-primary'}
                onClick={() => this.props.onKeepControlChange(!this.props.room?.keepControl)}
                disabled={!this.isRoomOwner()}
              >
                Keep Control
              </BootstrapButton>
            </div>
          </Tooltip>
        </div>
      );
    }
  };

  onCopyURLButtonClick = () => {
    if (this.props.room) {
      copyURLToClipboard(this.props.room);
      this.setState({ copyClipboardSnackbar: true });
    }
  };

  copyClipboardSnackbar = () => {
    const onClose: () => void = () => this.setState({ copyClipboardSnackbar: false });
    return copyURLSnackbar(this.state.copyClipboardSnackbar, onClose);
  };

  copyURLButton = () => {
    if (this.props.room) {
      const tooltip: string = 'Share the URL so your friends can join!';
      return (
        <div className={'SettingsOption'}>
          <Tooltip title={<span className={'MainMenuTooltip'}>{tooltip}</span>} placement="left">
            <div>
              <Button
                variant="outlined"
                color="primary"
                disabled={this.props.demoMode}
                startIcon={<LinkIcon />}
                onClick={this.onCopyURLButtonClick}
              >
                Share URL
              </Button>
            </div>
          </Tooltip>
        </div>
      );
    }

    return <></>;
  };

  roomSettingsHeader = () => {
    const tooltip: string = this.isRoomOwner()
      ? 'Only you can change these settings'
      : 'Only the owner can change these settings';

    return (
      <Tooltip title={<span className={'MainMenuTooltip'}>{tooltip}</span>} placement="left">
        <h5 style={{ marginBottom: '20px' }}>Room Settings</h5>
      </Tooltip>
    );
  };

  roomSettings = () => {
    if (this.props.room) {
      return (
        <div>
          {this.roomSettingsHeader()}
          <hr className={'SettingsDivider'} />
          {this.copyClipboardSnackbar()}
          {this.copyURLButton()}
          {this.togglePrivateRoom()}
          {this.toggleKeepControl()}
          {this.leaveRoomButton()}
          <br />
        </div>
      );
    }
  };

  displaySettings = () => {
    return (
      <div>
        <h5 style={{ marginBottom: '20px' }}>Display Settings</h5>
        <hr className={'SettingsDivider'} />
        {this.toggleBackground()}
        {this.toggleParticles()}
        {this.colorButtons()}
        {this.colorPicker()}
        <br />
      </div>
    );
  };

  otherSettings = () => {
    if (!this.props.demoMode) {
      return (
        <div>
          <h5 style={{ marginBottom: '20px' }}>Other Settings</h5>
          <hr className={'SettingsDivider'} />
          <div className={'SettingsOption'} style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginBottom: '15px' }}>
              <GoogleLogOutContainer />
            </div>
            <Tooltip
              title={
                <span className={'MainMenuTooltip'}>
                  Visit our website to learn more about YouTube Night
                </span>
              }
              placement="left"
            >
              <a href={'http://www.youtubenight.me'} target="_blank" rel={'noopener noreferrer'}>
                <BootstrapButton variant={'info'}>Learn More</BootstrapButton>
              </a>
            </Tooltip>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div className={'SettingsPaneContainer'}>
        {this.displaySettings()}
        {this.roomSettings()}
        {this.otherSettings()}
      </div>
    );
  }
}

export default SettingsPane;
