import ReduxState from '../../Redux/Types/ReduxState';
import { connect } from 'react-redux';
import MessageHistory from '../Components/MessageHistory';
import { getMessagesFromState } from '../../Server/Selectors/ServerSelectors';
import { getGoogleProfileFromState } from '../../GoogleProfile/Selectors/GoogleProfileSelectors';
import { getMovieNightRoomFromState } from '../../MainMenu/Selectors/MainMenuSelectors';
import { getCurrentURLFromState } from '../../MovieNight/Selectors/MovieNightSelectors';

const mapStateToProps = (state: ReduxState) => {
  return {
    messages: getMessagesFromState(state),
    myProfile: getGoogleProfileFromState(state),
    room: getMovieNightRoomFromState(state),
    currentURL: getCurrentURLFromState(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

const MessageHistoryContainer = connect(mapStateToProps, mapDispatchToProps)(MessageHistory);
export default MessageHistoryContainer;
