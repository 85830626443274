import React from 'react';
import { GoogleProfile } from '../../GoogleProfile/Types/GoogleProfile';
import { createSnackbar } from '../../Shared/Utils/SnackbarUtils';
import { emitVideoEvent, VIDEO_EVENT, VideoEventType } from '../../Shared/Types/SocketEvents';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { MovieNightUser } from '../../Server/Types/ServerTypes';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { MovieNightRoom } from '../../Shared/Types/Room';
import { containsAd } from '../../Shared/Utils/GeneralUtils';

interface VideoListenerProps {
  socket: SocketIOClient.Socket | undefined;
  myProfile: GoogleProfile | undefined;
  isReady: { [googleID: string]: boolean };
  users: { [googleID: string]: MovieNightUser };
  room: MovieNightRoom | undefined;
}

interface VideoListenerState {
  syncedMessage: boolean;
  syncingMessage: boolean;
  adMessage: boolean;
}

class VideoListener extends React.Component<VideoListenerProps, VideoListenerState> {
  private video: HTMLVideoElement;
  private capturePlay: boolean;
  private capturePause: boolean;
  private captureSeeked: boolean;
  private executingSeeked: boolean;
  private autoSyncInterval: any;

  // Disable Seeking
  private previousTime: number;
  private currentTime: number;
  private seekStart: number | null;

  constructor(props: VideoListenerProps) {
    super(props);

    this.video = document.getElementsByTagName('video')[0] as HTMLVideoElement;
    this.capturePlay = false;
    this.capturePause = false;
    this.captureSeeked = false;
    this.executingSeeked = false;

    this.previousTime = this.video.currentTime;
    this.currentTime = this.video.currentTime;
    this.seekStart = null;

    this.state = {
      syncedMessage: false,
      syncingMessage: false,
      adMessage: false,
    };
  }

  componentDidMount(): void {
    console.log('MOUNTING Video Listener');
    this.addEventListeners();

    this.props.socket?.on('Play', this.doPlay);
    this.props.socket?.on('Pause', this.doPause);
    this.props.socket?.on('Seeked', this.doSeeked);

    this.props.socket?.on('Syncing', () => {
      this.setState({ syncingMessage: true });
      this.setState({ syncedMessage: false });
    });

    this.props.socket?.on('SyncedExceptionForInvalidURLs', () => {
      if (this.state.syncingMessage) {
        this.setState({ syncingMessage: false });
        this.setState({ syncedMessage: true });
      }
    });

    this.props.socket?.on('Synced', () => {
      this.setState({ syncingMessage: false });
      this.setState({ syncedMessage: true });
      this.executingSeeked = false;
      this.capturePause = false;
    });

    this.props.socket?.on('SyncWithOwner', this.syncWithOwner);
    this.props.socket?.on('CompareStateWithOwner', this.compareStateWithOwner);
    this.props.socket?.on('ManualSync', this.sendStateToServerIfOwner);

    if (containsAd()) {
      this.setState({ adMessage: true });
    }
  }

  componentWillUnmount(): void {
    console.log('UNMOUNTING Video Listener');
    this.removeEventListeners();
  }

  addEventListeners(): void {
    // Play
    this.video.addEventListener('play', this.onPlayCapture);
    this.video.addEventListener('play', this.onPlay);

    // Pause
    this.video.addEventListener('pause', this.onPauseCapture);
    this.video.addEventListener('pause', this.onPause);

    // Seeking
    this.video.addEventListener('seeking', this.onSeeking);

    // Seeked
    this.video.addEventListener('seeked', this.onSeekedCapture);
    this.video.addEventListener('seeked', this.onSeeked);

    // Buffering
    this.video.addEventListener('canplaythrough', this.onCanPlayThrough);
    this.video.addEventListener('waiting', this.onWaiting);

    // Auto Sync
    this.autoSyncInterval = setInterval(this.sendStateToServerIfOwner, 5000);

    // Disable Seeking If Necessary
    this.video.addEventListener('timeupdate', this.onTimeUpdate);
  }

  removeEventListeners(): void {
    // Play
    this.video.removeEventListener('play', this.onPlayCapture);
    this.video.removeEventListener('play', this.onPlay);

    // Pause
    this.video.removeEventListener('pause', this.onPauseCapture);
    this.video.removeEventListener('pause', this.onPause);

    // Seeked
    this.video.removeEventListener('seeked', this.onSeekedCapture);
    this.video.removeEventListener('seeked', this.onSeeked);

    // Buffering
    this.video.removeEventListener('canplaythrough', this.onCanPlayThrough);
    this.video.removeEventListener('waiting', this.onWaiting);

    // Auto Sync
    clearInterval(this.autoSyncInterval);
  }

  // Keep Control
  notAllowedToControl = (): boolean => {
    if (this.props.room && this.props.room.keepControl) {
      if (this.props.room.owner !== this.props.myProfile?.googleID) {
        return true;
      }
    }

    return false;
  };

  onTimeUpdate = () => {
    this.previousTime = this.video.currentTime;
    this.currentTime = this.video.currentTime;

    if (this.video.duration - this.video.currentTime <= 1 && !containsAd()) {
      emitVideoEvent(this.props.socket, VideoEventType.Seeked, this.props.myProfile?.googleID, 0);
      this.doSeeked(0);
    }
  };

  // Snackbars -- Start
  adMessageSnackbar = () => {
    return (
      <Snackbar
        key={18}
        open={this.state.adMessage}
        onClose={() => this.setState({ adMessage: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={'AdWarningSnackbar SnackbarGeneral'}
        transitionDuration={0}
      >
        <Alert
          severity={'warning'}
          onClose={() => this.setState({ adMessage: false })}
          variant={'filled'}
        >
          <span style={{ textAlign: 'center', display: 'flex' }}>
            Let everyone know
            <br /> that you're watching an ad
          </span>
        </Alert>
      </Snackbar>
    );
  };

  syncedMessageSnackbar = () => {
    return createSnackbar(
      'Synced!',
      'success',
      1500,
      this.state.syncedMessage && !this.state.syncingMessage,
      () => this.setState({ syncedMessage: false }),
      false,
      11,
      'SynchronizingSuccessSnackbar',
    );
  };

  syncingMessageSnackbar = () => {
    let icons: JSX.Element[] = [];

    Object.keys(this.props.isReady).forEach((googleID, index) => {
      let googleProfile = this.props.users[googleID].googleProfile;
      let isReady = this.props.isReady[googleID];

      if (googleProfile) {
        icons.push(
          <div key={index} style={{ marginRight: '5px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img
                src={googleProfile.imageURL}
                alt={googleProfile.name}
                height={'20px'}
                width={'20px'}
                className={'HeaderProfilePicture'}
              />
              {isReady ? <CheckIcon /> : <CloseIcon />}
            </div>
          </div>,
        );
      }
      return <></>;
    });

    return (
      <Snackbar
        key={12}
        open={this.state.syncingMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => this.setState({ syncingMessage: false })}
        className={'SynchronizingSnackbar SnackbarGeneral'}
      >
        <Alert
          severity={'warning'}
          variant={'filled'}
          onClose={() => this.setState({ syncingMessage: false })}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <span style={{ marginBottom: '10px' }}>Syncing...</span>
            <div style={{ display: 'flex' }}>{icons}</div>
          </div>
        </Alert>
      </Snackbar>
    );
  };
  // Snackbars -- End

  // Sync -- Start
  syncWithOwner = (ownerID: string) => {
    this.capturePause = true;
    this.video.pause();

    if (this.props.myProfile?.googleID === ownerID) {
      this.props.socket?.emit('OwnerSyncTime', this.video.currentTime);
    }
  };

  sendStateToServerIfOwner = (shouldSendReply?: boolean) => {
    if (this.props.room && this.props.room.owner === this.props.myProfile?.googleID) {
      if (shouldSendReply || !this.state.syncingMessage) {
        this.props.socket?.emit('CurrentStateOfOwner', {
          currentTime: this.video.currentTime,
          paused: this.video.paused,
          readyState: this.video.readyState,
          shouldSendReply,
        });
      }
    }
  };

  computeInSyncWithOwner = (currentState: any) => {
    return (
      Math.abs(this.video.currentTime - currentState.currentTime) < 1 &&
      this.video.paused === currentState.paused &&
      this.video.readyState === currentState.readyState &&
      this.video.readyState === 4
    );
  };

  compareStateWithOwner = (currentState: any) => {
    const inSync = this.computeInSyncWithOwner(currentState);

    if (inSync && currentState.shouldSendReply) {
      this.props.socket?.emit('InSyncWithOwner');
    }
    // Do not emit out of sync for manual sync checks since that will
    // force a synchronization when one is already happening
    else if (!inSync && !currentState.shouldSendReply) {
      this.props.socket?.emit('OutOfSyncWithOwner');
    }
  };
  // Sync -- End

  // Play -- Start
  onPlayCapture = () => {
    if (this.capturePlay) {
      this.video.removeEventListener('play', this.onPlay);
      this.video.addEventListener('play', this.onPlay);
    }

    this.capturePlay = false;
  };

  onPlay = () => {
    if (this.notAllowedToControl()) {
      this.setState({ syncingMessage: true, syncedMessage: false });
      this.props.socket?.emit('NotAllowedToControl', { type: VideoEventType.Play });
      return;
    }

    this.setState({ syncingMessage: true, syncedMessage: false });
    this.capturePause = true;
    this.video.pause();

    // Prevent automatic-playing while seeking
    if (!this.executingSeeked) {
      emitVideoEvent(this.props.socket, VideoEventType.Play, this.props.myProfile?.googleID);
    }
  };

  doPlay = () => {
    this.capturePlay = true;
    this.video.play();
  };
  // Play -- End

  // Pause -- Start
  onPauseCapture = () => {
    if (this.video.readyState === 1) {
      this.video.removeEventListener('pause', this.onPause);
      this.video.addEventListener('pause', this.onPause);
    } else if (this.capturePause) {
      this.video.removeEventListener('pause', this.onPause);
      this.video.addEventListener('pause', this.onPause);
      this.capturePause = false;
    }
  };

  onPause = () => {
    if (this.notAllowedToControl()) {
      this.setState({ syncingMessage: true, syncedMessage: false });
      this.props.socket?.emit('NotAllowedToControl', { type: VideoEventType.Pause });
      return;
    }

    this.setState({ syncingMessage: true, syncedMessage: false });
    this.capturePlay = true;
    this.video.play().then(() => {
      emitVideoEvent(this.props.socket, VideoEventType.Pause, this.props.myProfile?.googleID);
    });
  };

  doPause = () => {
    this.capturePause = true;
    this.video.pause();
  };
  // Pause -- End

  // Seeked -- Start
  onSeekedCapture = () => {
    if (this.captureSeeked) {
      this.video.removeEventListener('seeked', this.onSeeked);
      this.video.addEventListener('seeked', this.onSeeked);
    }

    this.captureSeeked = false;
  };

  onSeeked = () => {
    if (this.notAllowedToControl()) {
      if (typeof this.seekStart == 'number') {
        if (this.currentTime > this.seekStart) {
          this.video.currentTime = this.seekStart;
        }

        this.seekStart = null;
      }

      this.setState({ syncingMessage: true, syncedMessage: false });
      this.props.socket?.emit('NotAllowedToControl', { type: VideoEventType.Seeked });
      return;
    }

    this.setState({ syncingMessage: true, syncedMessage: false });
    this.executingSeeked = true;
    this.capturePause = true;
    this.video.pause();
    emitVideoEvent(
      this.props.socket,
      VideoEventType.Seeked,
      this.props.myProfile?.googleID,
      this.video.currentTime,
    );
  };

  doSeeked = (seekTime: number) => {
    if (this.video.currentTime !== seekTime) {
      this.setState({ syncingMessage: true, syncedMessage: false });
      this.capturePause = true;
      this.video.pause();
      this.captureSeeked = true;
      this.video.currentTime = seekTime;

      // Update disable state
      this.previousTime = seekTime;
      this.currentTime = seekTime;
    } else {
      this.onCanPlayThrough();
    }
  };
  // Seeked -- End

  // Seeking -- Start
  onSeeking = () => {
    if (this.notAllowedToControl()) {
      if (this.seekStart === null) {
        this.seekStart = this.previousTime;
      }
      return;
    }

    this.props.socket?.emit(VIDEO_EVENT, { type: VideoEventType.Seeking });
    this.setState({ syncingMessage: true, syncedMessage: false });
  };
  // Seeking -- End

  // Buffering -- Start
  onCanPlayThrough = () => {
    emitVideoEvent(
      this.props.socket,
      VideoEventType.CanPlayThrough,
      this.props.myProfile?.googleID,
    );
  };

  onWaiting = () => {
    emitVideoEvent(
      this.props.socket,
      VideoEventType.Buffering,
      this.props.myProfile?.googleID,
      this.video.currentTime,
    );
  };
  // Buffering -- End

  render() {
    return (
      <div>
        {this.syncedMessageSnackbar()}
        {this.syncingMessageSnackbar()}
        {this.adMessageSnackbar()}
      </div>
    );
  }
}

export default VideoListener;
