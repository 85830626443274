import { GoogleProfileState, initialGoogleProfileState } from '../Types/GoogleProfileState';
import { GoogleProfileActions } from '../Actions/GoogleProfileActions';
import {
  CHANGE_SHOULD_INITIATE_AUTO_LOGIN,
  GOOGLE_LOG_IN_FAILURE,
  GOOGLE_LOG_IN_SUCCESS,
} from '../Modules/LogIn/Actions/GoogleLogInActions';
import {
  GOOGLE_LOG_OUT_FAILURE,
  GOOGLE_LOG_OUT_SUCCESS,
} from '../Modules/LogOut/Actions/GoogleLogOutActions';

export const googleProfileReducer = (
  state: GoogleProfileState = initialGoogleProfileState,
  action: GoogleProfileActions,
): GoogleProfileState => {
  switch (action.type) {
    case GOOGLE_LOG_IN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        googleProfile: action.payload,
      };

    case GOOGLE_LOG_OUT_SUCCESS:
      return {
        ...state,
        loggedIn: false,
        googleProfile: undefined,
      };

    case GOOGLE_LOG_IN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        googleProfile: undefined,
        logInError: action.payload,
      };

    // TBD
    case GOOGLE_LOG_OUT_FAILURE:
      return {
        ...state,
        logOutError: true,
      };

    case CHANGE_SHOULD_INITIATE_AUTO_LOGIN:
      return {
        ...state,
        shouldInitiateAutoLogin: action.payload,
      };

    default:
      return state;
  }
};

export default googleProfileReducer;
