import { GoogleProfile } from '../../GoogleProfile/Types/GoogleProfile';
import { MovieNightRoom } from '../../Shared/Types/Room';

export interface Message {
  googleID: string;
  message: string;
  serverTime: Date;
  systemMessage: boolean;
}

export const buildMessageDemoMode = (
  googleID: string | undefined,
  message: string,
  systemMessage: boolean = false,
): Message => {
  if (!googleID) {
    googleID = 'DemoUser';
  }

  return {
    googleID,
    message,
    serverTime: new Date(),
    systemMessage,
  };
};

export interface MovieNightUser {
  googleProfile: GoogleProfile | undefined;
  isTyping: boolean;
  room: MovieNightRoom | undefined;
}
