import React from 'react';
import socketIO from 'socket.io-client';
import { REST_API_ENDPOINT } from '../../Shared/Constants/SharedConstants';
import {
  UPDATE_ALL_USERS_EVENT,
  IS_VALID_USER_EVENT,
  UPDATE_ALL_MESSAGES_EVENT,
  PREEXISTING_LOGIN_ERROR,
  TYPE_ERROR_EVENT,
  ROOM_ERROR_EVENT,
  GOOGLE_ID_ERROR_EVENT,
  SERVER_INFORMATION_ERROR_EVENT,
  UPDATE_IS_READY_EVENT,
  UPDATE_PUBLIC_ROOMS_EVENT,
} from '../Types/ServerEventTypes';
import { GoogleProfile } from '../../GoogleProfile/Types/GoogleProfile';
import { Message, MovieNightUser } from '../Types/ServerTypes';
import { MovieNightRoom } from '../../Shared/Types/Room';
import { createSnackbar } from '../../Shared/Utils/SnackbarUtils';
import '../Styles/ServerSnackbarStyles.css';
import { ROOM_EVENT, RoomEventType } from '../../Shared/Types/SocketEvents';
import { isMyURLSameAsBaseURL, makeShareURLFromBaseURL } from '../../Shared/Utils/WebsiteURLUtils';

interface ServerListenerProps {
  googleProfile: GoogleProfile | undefined;
  onUpdateAllUsers: (users: { [googleID: string]: MovieNightUser }) => void;
  onUpdateAllMessages: (messages: Message[]) => void;
  onConnectSocket: (socket: SocketIOClient.Socket) => void;
  onSetMainMenuIsActive: (isActive: boolean) => void;
  onPreexistingLoginError: () => void;
  onUpdateMovieNightRoom: (movieNightRoom: MovieNightRoom | undefined) => void;
  onChangeSettingsPaneIsActive: (isActive: boolean) => void;
  onUpdateIsReady: (isReady: { [googleID: string]: boolean }) => void;
  users: { [googleID: string]: MovieNightUser };
  onUpdatePublicRooms: (rooms: MovieNightRoom[]) => void;
  demoMode?: boolean;
}

interface ServerListenerState {
  createRoomFailure: boolean;
  joinRoomFailure: boolean;
  leaveRoomSuccess: boolean;
  leaveRoomFailure: boolean;
  typeError: boolean;
  roomError: boolean;
  googleIDError: boolean;
  serverInformationError: boolean;
  updateRoomError: boolean;
}

class ServerListener extends React.PureComponent<ServerListenerProps, ServerListenerState> {
  private socket: SocketIOClient.Socket | undefined;

  constructor(props: ServerListenerProps) {
    super(props);

    this.state = {
      createRoomFailure: false,
      joinRoomFailure: false,
      leaveRoomSuccess: false,
      leaveRoomFailure: false,
      typeError: false,
      roomError: false,
      googleIDError: false,
      serverInformationError: false,
      updateRoomError: false,
    };
  }

  componentDidMount = (): void => {
    if (!this.props.demoMode) {
      this.socket = socketIO(REST_API_ENDPOINT);

      this.props.onConnectSocket(this.socket);
      this.socket.emit(IS_VALID_USER_EVENT, this.props.googleProfile);

      this.listenForErrors();
      this.listenForRoomEvents();
      this.listenForUpdateAllUsers();
      this.listenForUpdateAllMessages();
      this.listenForUpdateIsReady();
      this.listenForPublicRoomsUpdate();
    }
  };

  listenForRoomEvents = (): void => {
    this.socket?.on(ROOM_EVENT, (payload: any) => {
      switch (payload.type) {
        case RoomEventType.CreateRoomSuccess:
          this.props.onUpdateMovieNightRoom(payload.room);
          this.props.onSetMainMenuIsActive(false);
          break;

        case RoomEventType.JoinRoomSuccess:
          this.props.onUpdateMovieNightRoom(payload.room);
          this.props.onSetMainMenuIsActive(false);
          break;

        case RoomEventType.RedirectURL:
          if (!isMyURLSameAsBaseURL(payload.url, window.location.href, payload.roomName)) {
            window.location.href = makeShareURLFromBaseURL(payload.url, payload.roomName);
          }
          break;

        case RoomEventType.CreateRoomFailure:
          this.setState({ createRoomFailure: true });
          this.props.onUpdateMovieNightRoom(undefined);
          break;

        case RoomEventType.JoinRoomFailure:
          this.setState({ joinRoomFailure: true });
          break;

        case RoomEventType.LeaveRoomSuccess:
          this.setState({ leaveRoomSuccess: true });
          this.props.onUpdateMovieNightRoom(undefined);
          this.props.onSetMainMenuIsActive(true);
          this.props.onChangeSettingsPaneIsActive(false);
          break;

        case RoomEventType.LeaveRoomFailure:
          this.setState({ leaveRoomFailure: true });
          break;

        case RoomEventType.UpdateRoom:
          this.props.onUpdateMovieNightRoom(payload.room);
          break;

        case RoomEventType.UpdateRoomError:
          this.setState({ updateRoomError: true });
          break;
      }
    });
  };

  listenForErrors = (): void => {
    this.socket?.on(PREEXISTING_LOGIN_ERROR, () => {
      this.props.onPreexistingLoginError();
      alert('Error: you are already logged into YouTube Night in another tab');
    });

    this.socket?.on(TYPE_ERROR_EVENT, (objectWithProblems: any) => {
      this.setState({ typeError: true });
      console.log('!!! TYPE ERROR: ' + objectWithProblems);
    });

    this.socket?.on(ROOM_ERROR_EVENT, () => {
      this.setState({ roomError: true });
      console.log('!!! ROOM DOES NOT EXIST ERROR: ');
    });

    this.socket?.on(GOOGLE_ID_ERROR_EVENT, () => {
      this.setState({ googleIDError: true });
      console.log('!!! GOOGLE ID ERROR: ');
    });

    this.socket?.on(SERVER_INFORMATION_ERROR_EVENT, () => {
      this.setState({ serverInformationError: true });
      console.log('!!! SERVER INFORMATION ERROR');
    });
  };

  listenForUpdateAllUsers = (): void => {
    this.socket?.on(UPDATE_ALL_USERS_EVENT, (users: { [googleID: string]: MovieNightUser }) => {
      this.props.onUpdateAllUsers(users);
    });
  };

  listenForUpdateAllMessages = (): void => {
    this.socket?.on(UPDATE_ALL_MESSAGES_EVENT, (messages: Message[]) => {
      this.props.onUpdateAllMessages(messages);
    });
  };

  listenForUpdateIsReady = (): void => {
    this.socket?.on(UPDATE_IS_READY_EVENT, (isReady: { [googleID: string]: boolean }) => {
      this.props.onUpdateIsReady(isReady);
    });
  };

  listenForPublicRoomsUpdate = () => {
    this.socket?.on(UPDATE_PUBLIC_ROOMS_EVENT, (movieNightRooms: []) => {
      this.props.onUpdatePublicRooms(movieNightRooms);
    });
  };

  createRoomFailureSnackbar = () => {
    return createSnackbar(
      'Name is already taken',
      'warning',
      2000,
      this.state.createRoomFailure,
      () => this.setState({ createRoomFailure: false }),
      true,
      2,
      'CreateRoomFailure',
    );
  };

  joinRoomFailureSnackbar = () => {
    return createSnackbar(
      'Room does not exist',
      'warning',
      2000,
      this.state.joinRoomFailure,
      () => this.setState({ joinRoomFailure: false }),
      true,
      4,
      'JoinRoomFailure',
    );
  };

  leaveRoomSuccessSnackbar = () => {
    return createSnackbar(
      'Left the room',
      'success',
      2000,
      this.state.leaveRoomSuccess,
      () => this.setState({ leaveRoomSuccess: false }),
      true,
      5,
      'LeaveRoomSuccess',
    );
  };

  leaveRoomFailureSnackbar = () => {
    return createSnackbar(
      'An error occurred - please refresh the page',
      'error',
      2000,
      this.state.leaveRoomFailure,
      () => this.setState({ leaveRoomFailure: false }),
      true,
      6,
      'LeaveRoomFailure',
    );
  };

  typeErrorSnackbar = () => {
    return createSnackbar(
      'Type error - refresh please',
      'error',
      2000,
      this.state.typeError,
      () => this.setState({ typeError: false }),
      true,
      7,
    );
  };

  roomErrorSnackbar = () => {
    return createSnackbar(
      'Room does not exist - refresh please',
      'error',
      2000,
      this.state.roomError,
      () => this.setState({ roomError: false }),
      true,
      8,
      'RoomDoesNotExist',
    );
  };

  googleIDErrorSnackbar = () => {
    return createSnackbar(
      'Error with Google ID - refresh please',
      'error',
      2000,
      this.state.googleIDError,
      () => this.setState({ googleIDError: false }),
      true,
      9,
    );
  };

  serverInformationErrorSnackbar = () => {
    return createSnackbar(
      'Server information error - refresh please',
      'error',
      2000,
      this.state.serverInformationError,
      () => this.setState({ serverInformationError: false }),
      true,
      10,
    );
  };

  updateRoomError = () => {
    return createSnackbar(
      'Only the owner can change the room settings',
      'error',
      2000,
      this.state.updateRoomError,
      () => this.setState({ updateRoomError: false }),
      true,
      15,
    );
  };

  render() {
    return (
      <>
        {this.createRoomFailureSnackbar()}
        {this.joinRoomFailureSnackbar()}
        {this.leaveRoomSuccessSnackbar()}
        {this.leaveRoomFailureSnackbar()}
        {this.typeErrorSnackbar()}
        {this.roomErrorSnackbar()}
        {this.googleIDErrorSnackbar()}
        {this.serverInformationErrorSnackbar()}
        {this.updateRoomError()}
      </>
    );
  }
}

export default ServerListener;
