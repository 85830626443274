export interface MovieNightState {
  appHeight: string;
  isSettingsPaneActive: boolean;
  currentURL: string;
}

export const initialMovieNightState: MovieNightState = {
  appHeight: '100%',
  isSettingsPaneActive: false,
  currentURL: window.location.href,
};
