import ReduxState from './Types/ReduxState';
import { MovieNightUser } from '../Server/Types/ServerTypes';
import { getGoogleProfileFromState } from '../GoogleProfile/Selectors/GoogleProfileSelectors';
import { getIsTypingFromState } from '../MessageInput/Selectors/Selectors';
import { getMovieNightRoomFromState } from '../MainMenu/Selectors/MainMenuSelectors';

export const getMovieNightUserFromState = (state: ReduxState): MovieNightUser => {
  return {
    googleProfile: getGoogleProfileFromState(state),
    isTyping: getIsTypingFromState(state),
    room: getMovieNightRoomFromState(state),
  };
};
