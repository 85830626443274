export const CHANGE_IS_DARK_THEME_ACTIVE = 'CHANGE_IS_DARK_THEME_ACTIVE';
export interface ChangeIsDarkThemeActiveAction {
  type: typeof CHANGE_IS_DARK_THEME_ACTIVE;
  payload: boolean;
}
export const changeDarkThemeIsActiveActionCreator = (
  isActive: boolean,
): ChangeIsDarkThemeActiveAction => {
  return {
    type: CHANGE_IS_DARK_THEME_ACTIVE,
    payload: isActive,
  };
};

export const CHANGE_ARE_PARTICLES_DISABLED = 'CHANGE_ARE_PARTICLES_DISABLED';
export interface ChangeAreParticlesDisabledAction {
  type: typeof CHANGE_ARE_PARTICLES_DISABLED;
  payload: boolean;
}
export const changeAreParticlesDisabledActionCreator = (
  areDisabled: boolean,
): ChangeAreParticlesDisabledAction => {
  return {
    type: CHANGE_ARE_PARTICLES_DISABLED,
    payload: areDisabled,
  };
};

export const CHANGE_MY_COLOR = 'CHANGE_MY_COLOR';
export interface ChangeMyColorAction {
  type: typeof CHANGE_MY_COLOR;
  payload: string;
}
export const changeMyColorActionCreator = (color: string): ChangeMyColorAction => {
  return {
    type: CHANGE_MY_COLOR,
    payload: color,
  };
};

export const CHANGE_THEIR_COLOR = 'CHANGE_THEIR_COLOR';
export interface ChangeTheirColorAction {
  type: typeof CHANGE_THEIR_COLOR;
  payload: string;
}
export const changeTheirColorActionCreator = (color: string): ChangeTheirColorAction => {
  return {
    type: CHANGE_THEIR_COLOR,
    payload: color,
  };
};

export const CHANGE_PRIVATE_ROOM = 'CHANGE_PRIVATE_ROOM';
export interface ChangePrivateRoomAction {
  type: typeof CHANGE_PRIVATE_ROOM;
  payload: boolean;
}
export const changePrivateRoomActionCreator = (isPrivateRoom: boolean): ChangePrivateRoomAction => {
  return {
    type: CHANGE_PRIVATE_ROOM,
    payload: isPrivateRoom,
  };
};

export const CHANGE_KEEP_CONTROL = 'CHANGE_KEEP_CONTROL';
export interface ChangeKeepControlAction {
  type: typeof CHANGE_KEEP_CONTROL;
  payload: boolean;
}
export const changeKeepControlActionCreator = (keepControl: boolean): ChangeKeepControlAction => {
  return {
    type: CHANGE_KEEP_CONTROL,
    payload: keepControl,
  };
};

export type SettingsPaneActions =
  | ChangeIsDarkThemeActiveAction
  | ChangeAreParticlesDisabledAction
  | ChangeMyColorAction
  | ChangeTheirColorAction
  | ChangePrivateRoomAction
  | ChangeKeepControlAction;
