export const SUBMIT_MESSAGE = 'SUBMIT_MESSAGE';
export interface SubmitMessageAction {
  type: typeof SUBMIT_MESSAGE;
  payload: string;
}

export const submitMessageActionCreator = (message: string): SubmitMessageAction => {
  return {
    type: SUBMIT_MESSAGE,
    payload: message,
  };
};

export const UPDATE_IS_TYPING = 'UPDATE_IS_TYPING';
export interface UpdateIsTypingAction {
  type: typeof UPDATE_IS_TYPING;
  payload: boolean;
}

export const updateIsTypingActionCreator = (isTyping: boolean): UpdateIsTypingAction => {
  return {
    type: UPDATE_IS_TYPING,
    payload: isTyping,
  };
};

export type MessageInputActions = SubmitMessageAction | UpdateIsTypingAction;
