import React from 'react';
import '../Styles/MainMenu.css';
import {
  MainMenuExistingRoomContainerID,
  MainMenuID,
  MainMenuNewRoomContainerID,
  MainMenuShowAllRoomsContainerID,
} from '../../MovieNight/Constants/MovieNightConstants';
import { TextField, Tooltip } from '@material-ui/core';
import BootstrapButton from 'react-bootstrap/Button';
import { getBaseURL, getParameterByName, getYouTubeInfo } from '../../Shared/Utils/WebsiteURLUtils';
import { transitionDown } from '../../Shared/Utils/SnackbarUtils';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { MovieNightRoom, YoutubeInfo } from '../../Shared/Types/Room';
import MaterialTable from 'material-table';
import { UPDATE_PUBLIC_ROOMS_EVENT } from '../../Server/Types/ServerEventTypes';

interface MainMenuProps {
  onCreateRoom: (
    googleID: string | undefined,
    roomName: string,
    isPrivateRoom: boolean,
    keepControl: boolean,
    url: string,
    youtubeInfo: YoutubeInfo,
  ) => void;
  onJoinRoom: (roomName: string, url: string) => void;
  loggedIn: boolean;
  googleID: string | undefined;
  alreadyAutoJoined: boolean;
  onAutoJoinChange: (autoJoined: boolean) => void;
  publicRooms: MovieNightRoom[];
  socket: SocketIOClient.Socket | undefined;
}

interface MainMenuState {
  isMounted: boolean;
  keepControl: boolean;
  isPrivateRoom: boolean;
  joinRoomNameString: string;
  createRoomNameString: string;
  allowedCharactersSnackbar: boolean;
}

class MainMenu extends React.PureComponent<MainMenuProps, MainMenuState> {
  constructor(props: MainMenuProps) {
    super(props);

    this.state = {
      isMounted: false,
      keepControl: false,
      isPrivateRoom: true,
      joinRoomNameString: '',
      createRoomNameString: '',
      allowedCharactersSnackbar: false,
    };
  }

  componentDidMount(): void {
    this.setState({ isMounted: true });

    // Get public rooms
    if (this.props.socket) {
      this.props.socket.emit(UPDATE_PUBLIC_ROOMS_EVENT);
    }

    if (!this.props.alreadyAutoJoined && document.location.href.includes('startMovieNightApp')) {
      let roomName: string | null = getParameterByName('startMovieNightApp', window.location.href);

      if (roomName !== null) {
        this.setState({ joinRoomNameString: roomName });
        this.props.onJoinRoom(roomName, getBaseURL());
        this.props.onAutoJoinChange(true);
      }
    }
  }

  // Join Room Stuff Below
  renderJoinRoomButton = () => {
    return (
      <BootstrapButton
        variant={'outline-success'}
        disabled={!this.isValidRoomName(this.state.joinRoomNameString)}
        onClick={() => this.props.onJoinRoom(this.state.joinRoomNameString, getBaseURL())}
      >
        Join a YouTube Night!
      </BootstrapButton>
    );
  };

  renderJoinRoomNameTextField = () => {
    const tooltip: string = 'Type the room name you want to join';
    return (
      <Tooltip title={<span className={'MainMenuTooltip'}>{tooltip}</span>} placement="left">
        <div onKeyPressCapture={(event: any) => console.log(event)}>
          <TextField
            label="Room Name"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              this.setState({ joinRoomNameString: event.currentTarget.value })
            }
            value={this.state.joinRoomNameString}
          />
        </div>
      </Tooltip>
    );
  };

  renderJoinRoomContainer = (): JSX.Element => {
    return (
      <div id={MainMenuExistingRoomContainerID} className={'JoinRoomContainer DisableButtonHover'}>
        <h6 className={'MainMenuHeaderText'}>Join a Room</h6>
        <div style={{ marginBottom: '15px' }}>{this.renderJoinRoomNameTextField()}</div>
        <div>{this.renderJoinRoomButton()}</div>
      </div>
    );
  };

  // Public Room Stuff Below
  renderPublicRoomsContainer = (): JSX.Element => {
    return (
      <div
        id={MainMenuShowAllRoomsContainerID}
        className={'PublicRoomsContainer DisableButtonHover'}
      >
        <Tooltip
          title={<span className={'MainMenuTooltip'}>Refresh your page to reload the list</span>}
          placement="left"
        >
          <h6 className={'MainMenuHeaderText'}>Public Rooms</h6>
        </Tooltip>
        <div
          style={{
            overflowY: 'auto',
            height: '250px',
          }}
          className={'ScrollbarPublicRooms'}
        >
          <MaterialTable
            columns={[
              {
                title: 'Room Name',
                field: 'roomName',
                render: (rowData) => (
                  <Tooltip
                    title={<span className={'MainMenuTooltip'}>Click to Join</span>}
                    placement="left"
                  >
                    <div>{(rowData as { roomName: string }).roomName}</div>
                  </Tooltip>
                ),
              },
              {
                title: 'Video Title',
                field: 'youtubeInfo.title',
              },
              {
                title: 'Channel Name',
                field: 'youtubeInfo.channel',
              },
            ]}
            data={this.props.publicRooms}
            options={{
              toolbar: false,
              paging: false,
              filtering: true,
            }}
            onRowClick={(_event, rowData, _togglePanel) => {
              if (rowData) {
                this.props.onJoinRoom(rowData.roomName, getBaseURL());
              }
            }}
          />
        </div>
      </div>
    );
  };

  // Create Room Stuff Below
  renderCreateRoomNameTextField = () => {
    const tooltip: string = 'Type a unique room name here';
    return (
      <Tooltip title={<span className={'MainMenuTooltip'}>{tooltip}</span>} placement="left">
        <TextField
          label="Room Name"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            this.setState({ createRoomNameString: event.currentTarget.value })
          }
          value={this.state.createRoomNameString}
          InputProps={{ style: { fontSize: '15px' } }}
        />
      </Tooltip>
    );
  };

  renderPrivateRoomButton = () => {
    const tooltip: string = 'A public room allows strangers to join';
    const onClick = () => this.setState({ isPrivateRoom: !this.state.isPrivateRoom });

    return (
      <Tooltip title={<span className={'MainMenuTooltip'}>{tooltip}</span>} placement="left">
        <BootstrapButton
          variant={this.state.isPrivateRoom ? 'primary' : 'outline-primary'}
          onClick={onClick}
        >
          Private Room
        </BootstrapButton>
      </Tooltip>
    );
  };

  renderKeepControlButton = () => {
    const tooltip: string =
      '(Beta) You have playback control but anyone can pick the URL - not recommended';
    const onClick = () => this.setState({ keepControl: !this.state.keepControl });

    return (
      <Tooltip title={<span className={'MainMenuTooltip'}>{tooltip}</span>} placement="left">
        <BootstrapButton
          variant={this.state.keepControl ? 'primary' : 'outline-primary'}
          onClick={onClick}
        >
          Keep Control
        </BootstrapButton>
      </Tooltip>
    );
  };

  isValidRoomName = (roomName: string) => {
    if (!roomName.match('^[A-Za-z0-9_-]*$')) {
      this.setState({ allowedCharactersSnackbar: true });
      return false;
    }

    return roomName !== '';
  };

  renderCreateNewRoomButton = () => {
    return (
      <BootstrapButton
        variant={'outline-success'}
        disabled={!this.isValidRoomName(this.state.createRoomNameString)}
        onClick={() =>
          this.props.onCreateRoom(
            this.props.googleID,
            this.state.createRoomNameString,
            this.state.isPrivateRoom,
            this.state.keepControl,
            getBaseURL(),
            getYouTubeInfo(),
          )
        }
      >
        Start a YouTube Night!
      </BootstrapButton>
    );
  };

  renderNewRoomContainer = (): JSX.Element => {
    return (
      <div id={MainMenuNewRoomContainerID} className={'NewRoomContainer DisableButtonHover'}>
        <h6 className={'MainMenuHeaderText'}>Create a Room</h6>
        <div style={{ marginBottom: '15px' }}>{this.renderCreateRoomNameTextField()}</div>
        <div style={{ marginBottom: '15px' }}>{this.renderPrivateRoomButton()}</div>
        <div style={{ marginBottom: '15px' }}>{this.renderKeepControlButton()}</div>
        <div>{this.renderCreateNewRoomButton()}</div>
      </div>
    );
  };

  allowedCharactersNotificationSnackbar = () => {
    return (
      <Snackbar
        key={13}
        open={this.state.allowedCharactersSnackbar}
        autoHideDuration={1000}
        onClose={() => this.setState({ allowedCharactersSnackbar: false })}
        TransitionComponent={transitionDown}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={' SnackbarGeneral RoomNameAllowedCharacters'}
      >
        <Alert severity={'warning'} variant={'filled'}>
          <div>Only use letters, numbers,</div>
          <div>dashes, and underscores</div>
        </Alert>
      </Snackbar>
    );
  };

  render() {
    if (this.state.isMounted) {
      return (
        <div id={MainMenuID} className={'MainMenuContainer'}>
          <div>{this.renderJoinRoomContainer()}</div>
          <div>{this.renderNewRoomContainer()}</div>
          {/*<div>{this.renderPublicRoomsContainer()}</div>*/}
          {this.allowedCharactersNotificationSnackbar()}
        </div>
      );
    }

    return <></>;
  }
}

export default MainMenu;
