import React from 'react';
import { Card, CardDeck } from 'react-bootstrap';
import GitLabIcon from '../Static/GitLabIcon.png';
import ReactIcon from '../Static/ReactIcon.png';
import ReduxIcon from '../Static/ReduxIcon.png';
import TypeScriptIcon from '../Static/TypeScriptIcon.png';
import ReduxSagaIcon from '../Static/ReduxSagaIcon.png';
import SocketIOIcon from '../Static/SocketIOIcon.png';
import ChromeIcon from '../Static/ChromeIcon.png';
import MaterialUIIcon from '../Static/MaterialUIIcon.svg';
import ReactBootstrapIcon from '../Static/ReactBootstrapIcon.svg';
import StylusIcon from '../Static/StylusIcon.png';
import DockerIcon from '../Static/DockerIcon.png';
import AWSIcon from '../Static/AWSIcon.png';
import HerokuIcon from '../Static/HerokuIcon.png';
import JestIcon from '../Static/JestIcon.png';
import PrettierIcon from '../Static/PrettierIcon.png';
import Jumbotron from 'react-bootstrap/Jumbotron';
import ScrollAnimation from 'react-animate-on-scroll';
import '../Styles/AboutCards.css';
import Emoji from 'react-emoji-render';

class AboutCards extends React.PureComponent {
  makeCard = (image: string, title: string, text: string, className: string, imageURL: string) => {
    return (
      <Card
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card.Body style={{ paddingBottom: '0' }}>
          <Card.Title style={{ fontSize: 'calc(14px + .4vw)', textAlign: 'center' }}>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true} offset={75}>
              {title}
            </ScrollAnimation>
          </Card.Title>
          <Card.Text>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true} offset={75}>
              {text}
            </ScrollAnimation>
          </Card.Text>
        </Card.Body>
        <a href={imageURL}>
          <ScrollAnimation animateIn="fadeIn" animateOnce={true} offset={75}>
            <Card.Img src={image} className={className} />
          </ScrollAnimation>
        </a>
      </Card>
    );
  };

  createListElement = (title: string, url: string) => {
    return (
      <ScrollAnimation animateIn="fadeIn" animateOnce={true} offset={75}>
        <a href={url}>
          <li className={'NoLinkColor'}>{title}</li>
        </a>
      </ScrollAnimation>
    );
  };

  render() {
    return (
      <div id={'AboutCards'} style={{ fontSize: 'calc(12px + 0.15vw)', textAlign: 'center' }}>
        <div className={'WebsiteMainTitleText'} style={{ marginTop: '45px', marginBottom: '45px' }}>
          <span className={'YouTubeColor'}>A</span>
          <span>bout</span>
        </div>
        <div style={{ marginBottom: '70px', textAlign: 'left' }}>
          <Jumbotron>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true} offset={50}>
              <h1>A better way to spend your nights</h1>
            </ScrollAnimation>
            <br />
            <div style={{ fontSize: 'calc(12px + .25vw)' }}>
              <p>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true} offset={75}>
                  Have you ever wondered whether your friends actually watch the YouTube videos you
                  send them? No more excuses... now you can watch with them!
                </ScrollAnimation>
              </p>
              <p>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true} offset={75}>
                  This is an experimental project created by a student at the University of Texas at
                  Austin. Please consider helping support our server costs on{' '}
                  <a href={'https://www.patreon.com/YouTubeNight'} className={'YouTubeLinkColor'}>
                    Patreon
                  </a>{' '}
                  - all profits will be donated to{' '}
                  <a href={'https://www.feedingamerica.org/'} className={'YouTubeLinkColor'}>
                    {' '}
                    Feeding America
                  </a>
                  <Emoji text={' <3.'} />
                </ScrollAnimation>
              </p>
              <p>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true} offset={75}>
                  If this project generates enough interest, the code will be open-sourced. The
                  tools used to develop this application are listed below <Emoji text={':)'} />.
                </ScrollAnimation>
              </p>
            </div>
          </Jumbotron>
        </div>
        <CardDeck>
          {this.makeCard(
            GitLabIcon,
            'GitLab',
            'Provides VCS, issue tracking, and CI/CD integration',
            'GitLabIcon',
            'https://gitlab.com/',
          )}
          {this.makeCard(
            ReactIcon,
            'React',
            'A frontend library with reusable components',
            'ReactIcon',
            'https://reactjs.org/',
          )}
          {this.makeCard(
            ReduxIcon,
            'Redux',
            'Manages application state with reducers',
            'ReduxIcon',
            'https://redux.js.org/',
          )}
          {this.makeCard(
            ReduxSagaIcon,
            'Saga',
            'Middleware for handling async side effects',
            'ReduxSagaIcon',
            'https://redux-saga.js.org/',
          )}
          {this.makeCard(
            TypeScriptIcon,
            'TypeScript',
            'A strongly-typed superset of JavaScript',
            'TypeScriptIcon',
            'https://www.typescriptlang.org/',
          )}
        </CardDeck>
        <CardDeck style={{ marginTop: '40px' }}>
          {this.makeCard(
            SocketIOIcon,
            'Socket.IO',
            'Enables real-time communication with WebSockets',
            'SocketIOIcon',
            'https://socket.io/',
          )}
          {this.makeCard(
            ChromeIcon,
            'Chrome',
            'The Chrome API allows extensions & content scripts',
            'ChromeIcon',
            'https://www.google.com/chrome/',
          )}
          {this.makeCard(
            MaterialUIIcon,
            'Material UI',
            'UI/UX components adhering to material design',
            'MaterialUIIcon',
            'https://material-ui.com/',
          )}
          {this.makeCard(
            ReactBootstrapIcon,
            'Bootstrap',
            'Provides simple & responsive frontend components',
            'ReactBootstrapIcon',
            'https://react-bootstrap.github.io/',
          )}
          {this.makeCard(
            StylusIcon,
            'Stylus',
            'A dynamic, feature-rich stylesheet preprocessor',
            'StylusIcon',
            'https://stylus-lang.com/',
          )}
        </CardDeck>
        <CardDeck style={{ marginTop: '40px' }}>
          {this.makeCard(
            DockerIcon,
            'Docker',
            'Provides isolated, scalable containers for the backend',
            'DockerIcon',
            'https://www.docker.com/',
          )}
          {this.makeCard(
            AWSIcon,
            'AWS',
            'Amplify seamlessly hosts websites with auto-deployment',
            'AWSIcon',
            'https://aws.amazon.com/',
          )}
          {this.makeCard(
            HerokuIcon,
            'Heroku',
            'Deploys the backend in scalable Dyno formations',
            'HerokuIcon',
            'https://www.heroku.com/',
          )}
          {this.makeCard(
            JestIcon,
            'Jest',
            'Enables test-driven development of JavaScript projects',
            'JestIcon',
            'https://jestjs.io/',
          )}
          {this.makeCard(
            PrettierIcon,
            'Prettier',
            'Formats code along with ESLint to ensure best practices',
            'PrettierIcon',
            'https://prettier.io/',
          )}
        </CardDeck>

        <Jumbotron style={{ marginTop: '40px', textAlign: 'left' }}>
          <h1>
            {' '}
            <ScrollAnimation animateIn="fadeIn" animateOnce={true} offset={75}>
              Other tools
            </ScrollAnimation>
          </h1>
          <br />
          <div style={{ fontSize: 'calc(12px + .25vw)' }}>
            <ul>
              {this.createListElement('React Color', 'https://github.com/casesandberg/react-color')}
              {this.createListElement('React Lottie', 'https://github.com/airbnb/lottie-web')}
              {this.createListElement('Redux Logger', 'https://github.com/LogRocket/redux-logger')}
              {this.createListElement(
                'React Particles',
                'https://github.com/Wufe/react-particles-js',
              )}
              {this.createListElement(
                'React App Rewired',
                'https://github.com/timarney/react-app-rewired',
              )}
              {this.createListElement(
                'React Emoji Render',
                'https://github.com/tommoor/react-emoji-render',
              )}
              {this.createListElement(
                'React Google Button',
                'https://github.com/prescottprue/react-google-button',
              )}
              {this.createListElement(
                'React Awesome Reveal',
                'https://github.com/dennismorello/react-awesome-reveal',
              )}
              {this.createListElement(
                'React Animate On Scroll',
                'https://github.com/dbramwell/react-animate-on-scroll',
              )}
              {this.createListElement(
                'Redux DevTools Extension',
                'https://github.com/zalmoxisus/redux-devtools-extension',
              )}
              {this.createListElement(
                'Lottie Animation - Popcorn',
                'https://lottiefiles.com/17662-videos-button',
              )}
              {this.createListElement(
                'Lottie Animation - Background',
                'https://lottiefiles.com/12106-website-background-transition',
              )}
              {this.createListElement(
                'Icons made by Google from www.flaticon.com',
                'https://www.flaticon.com/packs/image',
              )}
            </ul>
            <br />
            <div style={{ fontSize: 'calc(12px + .25vw)' }}>
              <p>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true} offset={75}>
                  It's possible I forgot a few here or there so if your library or icon is not
                  mentioned here, please let me know and I'll add it to the list ASAP! Also if your
                  icon is being misused, let me know and I'll remove it. My email is{' '}
                  <a href={'mailto:YTNightExtension@gmail.com'} className={'YouTubeLinkColor'}>
                    YTNightExtension@gmail.com
                  </a>
                  .
                </ScrollAnimation>
              </p>
            </div>
          </div>
        </Jumbotron>
      </div>
    );
  }
}

export default AboutCards;
